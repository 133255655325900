import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pollingCallsSatAuthorization: 0,
  pollingCallsBuroAuthorization: 0,
  isOpenChatAI: false,
  isChatAIEnabled: false,
  questionChatAI: '',
  isSettingsTabOpen: false,
};

const pulso = createSlice({
  name: 'pulso',
  initialState,
  reducers: {
    incrementPollingCallsSatAuthorization: (state) => {
      state.pollingCallsSatAuthorization += 1;
    },
    clearPollingCountSatAuthorization: (state) => {
      state.pollingCallsSatAuthorization = 0;
    },
    incrementPollingCallsBuroAuthorization: (state) => {
      state.pollingCallsBuroAuthorization += 1;
    },
    clearPollingCountBuroAuthorization: (state) => {
      state.pollingCallsBuroAuthorization = 0;
    },
    toggleChatAI: (state) => {
      state.isOpenChatAI = !state.isOpenChatAI;
    },
    showChatAI: (state, action) => {
      state.isOpenChatAI = action.payload.show;
      state.questionChatAI = action.payload.questionChatAI;
    },
    showSettings: (state, action) => {
      state.isOpenChatAI = true;
      state.isSettingsTabOpen = action.payload.show;
    },
    setChatAIEnabled: (state, action) => {
      state.isChatAIEnabled = action.payload;
    },
    toggleChatAIEnabled: (state) => {
      state.isChatAIEnabled = !state.isChatAIEnabled;
      if (state.isChatAIEnabled) {
        state.isSettingsTabOpen = false;
      }
    },
  },
});

export const { reducer, name: reducerPath } = pulso;
export const {
  incrementPollingCallsSatAuthorization,
  clearPollingCountSatAuthorization,
  incrementPollingCallsBuroAuthorization,
  clearPollingCountBuroAuthorization,
  toggleChatAI,
  showChatAI,
  showSettings,
  isSettingsTabOpen,
  isChatAIEnabled,
  toggleChatAIEnabled,
  setChatAIEnabled,
} = pulso.actions;
