const CookieNames = {
  token: 'token',
  refreshToken: 'refreshToken',
  isLoggedIn: 'isLoggedIn',
  redirectCial: 'redirect_cial',
  currentCompanyId: 'currentCompanyId',
  selectedLanguage: 'selectedLanguage',
  geo: 'geo',
  cialUrl: 'cialUrl',
  country: 'country',
};

export default CookieNames;
