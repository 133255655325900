import { isMobileOnly } from 'react-device-detect';

// Scores Playbook
const DRIFT_INTERACTION_ID_FOR_SCORES_ES = Number(process.env.NEXT_PUBLIC_DRIFT_INTERACTION_ID_FOR_SCORES_ES);
const DRIFT_INTERACTION_ID_FOR_SCORES_EN = Number(process.env.NEXT_PUBLIC_DRIFT_INTERACTION_ID_FOR_SCORES_EN);
const DRIFT_INTERACTION_ID_FOR_SCORES_PT = Number(process.env.NEXT_PUBLIC_DRIFT_INTERACTION_ID_FOR_SCORES_PT);

// Error & Contact Playbook
const DRIFT_INTERACTION_ID_FOR_ERROR_AND_CONTACT_ES = Number(
  process.env.NEXT_PUBLIC_DRIFT_INTERACTION_ID_FOR_ERROR_AND_CONTACT_ES,
);
const DRIFT_INTERACTION_ID_FOR_ERROR_AND_CONTACT_EN = Number(
  process.env.NEXT_PUBLIC_DRIFT_INTERACTION_ID_FOR_ERROR_AND_CONTACT_EN,
);
const DRIFT_INTERACTION_ID_FOR_ERROR_AND_CONTACT_PT = Number(
  process.env.NEXT_PUBLIC_DRIFT_INTERACTION_ID_FOR_ERROR_AND_CONTACT_PT,
);

// Order Report Playbook
const DRIFT_INTERACTION_ID_FOR_ORDER_REPORT_ES = Number(
  process.env.NEXT_PUBLIC_DRIFT_INTERACTION_ID_FOR_ORDER_REPORT_ES,
);
const DRIFT_INTERACTION_ID_FOR_ORDER_REPORT_EN = Number(
  process.env.NEXT_PUBLIC_DRIFT_INTERACTION_ID_FOR_ORDER_REPORT_EN,
);
const DRIFT_INTERACTION_ID_FOR_ORDER_REPORT_PT = Number(
  process.env.NEXT_PUBLIC_DRIFT_INTERACTION_ID_FOR_ORDER_REPORT_PT,
);

// Pulso Demo Playbook
const DRIFT_INTERACTION_ID_FOR_PULSO_DEMO_ES = Number(process.env.NEXT_PUBLIC_DRIFT_INTERACTION_ID_FOR_PULSO_DEMO_ES);
const DRIFT_INTERACTION_ID_FOR_PULSO_DEMO_EN = Number(process.env.NEXT_PUBLIC_DRIFT_INTERACTION_ID_FOR_PULSO_DEMO_EN);
const DRIFT_INTERACTION_ID_FOR_PULSO_DEMO_PT = Number(process.env.NEXT_PUBLIC_DRIFT_INTERACTION_ID_FOR_PULSO_DEMO_PT);

// Logged-In Support Playbook
const DRIFT_INTERACTION_ID_LOGGED_IN_SUPPORT_ES = Number(
  process.env.NEXT_PUBLIC_DRIFT_INTERACTION_ID_LOGGED_IN_SUPPORT_ES,
);
const DRIFT_INTERACTION_ID_LOGGED_IN_SUPPORT_EN = Number(
  process.env.NEXT_PUBLIC_DRIFT_INTERACTION_ID_LOGGED_IN_SUPPORT_EN,
);
const DRIFT_INTERACTION_ID_LOGGED_IN_SUPPORT_PT = Number(
  process.env.NEXT_PUBLIC_DRIFT_INTERACTION_ID_LOGGED_IN_SUPPORT_PT,
);

export const getDriftInteractionIdForScores = (language = 'en') => {
  let driftInteractionId;
  switch (language) {
    case 'es': {
      driftInteractionId = DRIFT_INTERACTION_ID_FOR_SCORES_ES;
      window?.drift?.config({
        locale: 'es',
        messages: {
          welcomeMessage: '¡Hola!',
          awayMessage: 'Oye, no estamos aquí ahora, pero deja un mensaje.',
          emailCaptureMessage: 'Deje su correo electrónico para que podamos responderle.',
          thanksYouMessage: 'Gracias, le daremos seguimiento pronto.',
        },
      });
      break;
    }
    case 'en': {
      driftInteractionId = DRIFT_INTERACTION_ID_FOR_SCORES_EN;
      window?.drift?.config({
        locale: 'en',
        messages: {
          welcomeMessage: 'Hey there!',
          awayMessage: "Hey, we're not here right now, but leave a message.",
          emailCaptureMessage: 'Leave your email so we can get back to you.',
          thankYouMessage: "Thanks we'll follow up soon.",
        },
      });
      break;
    }
    case 'pt': {
      driftInteractionId = DRIFT_INTERACTION_ID_FOR_SCORES_PT;
      window?.drift?.config({
        locale: 'pt-br',
        messages: {
          welcomeMessage: 'Olá!',
          awayMessage: 'Ei, não estamos aqui agora, mas deixe uma mensagem.',
          emailCaptureMessage: 'Deixe seu e-mail para que possamos retornar.',
          thankYouMessage: 'Obrigado, entraremos em contato em breve.',
        },
      });
      break;
    }
    default: {
      driftInteractionId = DRIFT_INTERACTION_ID_FOR_SCORES_EN;
      window?.drift?.config({
        locale: 'en',
        messages: {
          welcomeMessage: 'Hey there!',
          awayMessage: "Hey, we're not here right now, but leave a message.",
          emailCaptureMessage: 'Leave your email so we can get back to you.',
          thankYouMessage: "Thanks we'll follow up soon.",
        },
      });
    }
  }

  return driftInteractionId;
};

export const getDriftInteractionIdForErrorAndContact = (language = 'en') => {
  let driftInteractionId;
  switch (language) {
    case 'es': {
      driftInteractionId = DRIFT_INTERACTION_ID_FOR_ERROR_AND_CONTACT_ES;
      window?.drift?.config({
        locale: 'es',
        messages: {
          welcomeMessage: '¡Hola!',
          awayMessage: 'Oye, no estamos aquí ahora, pero deja un mensaje.',
          emailCaptureMessage: 'Deje su correo electrónico para que podamos responderle.',
          thanksYouMessage: 'Gracias, le daremos seguimiento pronto.',
        },
      });
      break;
    }
    case 'en': {
      driftInteractionId = DRIFT_INTERACTION_ID_FOR_ERROR_AND_CONTACT_EN;
      window?.drift?.config({
        locale: 'en',
        messages: {
          welcomeMessage: 'Hey there!',
          awayMessage: "Hey, we're not here right now, but leave a message.",
          emailCaptureMessage: 'Leave your email so we can get back to you.',
          thankYouMessage: "Thanks we'll follow up soon.",
        },
      });
      break;
    }
    case 'pt': {
      driftInteractionId = DRIFT_INTERACTION_ID_FOR_ERROR_AND_CONTACT_PT;
      window?.drift?.config({
        locale: 'pt-br',
        messages: {
          welcomeMessage: 'Olá!',
          awayMessage: 'Ei, não estamos aqui agora, mas deixe uma mensagem.',
          emailCaptureMessage: 'Deixe seu e-mail para que possamos retornar.',
          thankYouMessage: 'Obrigado, entraremos em contato em breve.',
        },
      });
      break;
    }
    default: {
      driftInteractionId = DRIFT_INTERACTION_ID_FOR_ERROR_AND_CONTACT_EN;
      window?.drift?.config({
        locale: 'en',
        messages: {
          welcomeMessage: 'Hey there!',
          awayMessage: "Hey, we're not here right now, but leave a message.",
          emailCaptureMessage: 'Leave your email so we can get back to you.',
          thankYouMessage: "Thanks we'll follow up soon.",
        },
      });
    }
  }

  return driftInteractionId;
};

export const getDriftInteractionIdForOrderReport = (language = 'en') => {
  let driftInteractionId;
  switch (language) {
    case 'es': {
      driftInteractionId = DRIFT_INTERACTION_ID_FOR_ORDER_REPORT_ES;
      window?.drift?.config({
        locale: 'es',
        messages: {
          welcomeMessage: '¡Hola!',
          awayMessage: 'Oye, no estamos aquí ahora, pero deja un mensaje.',
          emailCaptureMessage: 'Deje su correo electrónico para que podamos responderle.',
          thanksYouMessage: 'Gracias, le daremos seguimiento pronto.',
        },
      });
      break;
    }
    case 'en': {
      driftInteractionId = DRIFT_INTERACTION_ID_FOR_ORDER_REPORT_EN;
      window?.drift?.config({
        locale: 'en',
        messages: {
          welcomeMessage: 'Hey there!',
          awayMessage: "Hey, we're not here right now, but leave a message.",
          emailCaptureMessage: 'Leave your email so we can get back to you.',
          thankYouMessage: "Thanks we'll follow up soon.",
        },
      });
      break;
    }
    case 'pt': {
      driftInteractionId = DRIFT_INTERACTION_ID_FOR_ORDER_REPORT_PT;
      window?.drift?.config({
        locale: 'pt-br',
        messages: {
          welcomeMessage: 'Olá!',
          awayMessage: 'Ei, não estamos aqui agora, mas deixe uma mensagem.',
          emailCaptureMessage: 'Deixe seu e-mail para que possamos retornar.',
          thankYouMessage: 'Obrigado, entraremos em contato em breve.',
        },
      });
      break;
    }
    default: {
      driftInteractionId = DRIFT_INTERACTION_ID_FOR_ORDER_REPORT_EN;
      window?.drift?.config({
        locale: 'en',
        messages: {
          welcomeMessage: 'Hey there!',
          awayMessage: "Hey, we're not here right now, but leave a message.",
          emailCaptureMessage: 'Leave your email so we can get back to you.',
          thankYouMessage: "Thanks we'll follow up soon.",
        },
      });
    }
  }

  return driftInteractionId;
};

export const getDriftInteractionIdForPulsoDemo = (language = 'en') => {
  let driftInteractionId;
  switch (language) {
    case 'es': {
      driftInteractionId = DRIFT_INTERACTION_ID_FOR_PULSO_DEMO_ES;
      break;
    }
    case 'en': {
      driftInteractionId = DRIFT_INTERACTION_ID_FOR_PULSO_DEMO_EN;
      break;
    }
    case 'pt': {
      driftInteractionId = DRIFT_INTERACTION_ID_FOR_PULSO_DEMO_PT;
      break;
    }
    default: {
      driftInteractionId = DRIFT_INTERACTION_ID_FOR_PULSO_DEMO_EN;
    }
  }

  return driftInteractionId;
};

export const getDriftInteractionIdForLoggedInSupport = (language = 'en') => {
  let driftInteractionId;
  switch (language) {
    case 'es': {
      driftInteractionId = DRIFT_INTERACTION_ID_LOGGED_IN_SUPPORT_ES;
      break;
    }
    case 'en': {
      driftInteractionId = DRIFT_INTERACTION_ID_LOGGED_IN_SUPPORT_EN;
      break;
    }
    case 'pt': {
      driftInteractionId = DRIFT_INTERACTION_ID_LOGGED_IN_SUPPORT_PT;
      break;
    }
    default: {
      driftInteractionId = DRIFT_INTERACTION_ID_LOGGED_IN_SUPPORT_EN;
    }
  }

  return driftInteractionId;
};

export const startDrift = (driftInteractionId, user) => {
  if (typeof window !== 'undefined') {
    if (window?.drift?.api) {
      // Start the drift interaction
      window?.drift?.api?.startInteraction({ interactionId: driftInteractionId });

      // Identify user if it's logged in
      if (user) {
        identifyUser(user);
      }
    } else {
      alert("A Drift chat will open in production. Drift isn't loaded in this environment.");
    }
  }
};

export const identifyUser = (user) =>
  window?.drift?.identify(user.email, {
    email: user.email,
    phone: user.phone,
    name: user.name,
    firstName: user.firstName,
    lastName: user.lastName,
    aclId: user.aclId,
  });

export const setAttributes = (attributes) => window?.drift?.api?.setUserAttributes(attributes);

export const bootstrapDriftChat = ({ retryCount = 0, windowSize = { width: 0, height: 0 } }) => {
  // stop trying after 10 attempts
  if (retryCount >= 10) {
    return;
  }

  if (typeof window !== 'undefined') {
    if (!window?.drift) {
      // wait for drift to load
      setTimeout(
        () =>
          bootstrapDriftChat({
            windowSize,
            retryCount: retryCount + 1,
          }),
        500,
      );
      return;
    }

    window?.drift?.on('ready', function (api) {
      // set the widget position
      if (windowSize.width && windowSize.width < 1024) {
        window?.drift?.config({
          verticalOffset: 70,
          horizontalOffset: 2,
        });
      }

      // hide the widget when app is ready and user is logged in
      api.widget?.hide();

      // event listener for when the message is received
      window?.drift?.on('message', function () {
        api?.widget?.show();
      });

      // event listener for when the chat is closed
      window?.drift?.on('chatClose', function () {
        // workaround to not freeze the page after closing drift chat in mobile browser
        if (isMobileOnly) {
          api?.widget?.show();
        }
      });
    });
  }
};
