import { AdminCompanyDto } from 'store/models/Company';
import { getPathWithoutLanguage } from 'utils/countryRoute';
import { localeSubpathsLanguage } from 'utils/localeConstants';

export const companyPath = (companyId: string, companySlug: string): string => `/company/${companyId}/${companySlug}`;

export const personPath = (companyId: string, companySlug: string, personId: string, personSlug: string): string =>
  `${companyPath(companyId, companySlug)}/team/${personId}/${personSlug}`;

export const getIndustrySectorPath = (sectorId: string, sectorSlug: string): string =>
  `/browse-industries/${sectorId}/${sectorSlug}`;

export const getIndustrySubjectPath = (
  sectorId: string,
  sectorSlug: string,
  subjectId: string,
  subjectSlug: string,
): string => `/browse-industries/${sectorId}/${sectorSlug}/${subjectId}/${subjectSlug}`;

export const getLocationCountryPath = (countryId: string, countrySlug: string): string =>
  `/browse-locations/${countryId}/${countrySlug}`;

export const getLocationStatePath = (
  countryId: string,
  countrySlug: string,
  stateId: string,
  stateSlug: string,
): string => `/browse-locations/${countryId}/${countrySlug}/${stateId}/${stateSlug}`;

export const getCompanyPath = (company: AdminCompanyDto): string => {
  return `/company/${company.aclCompanyId}/admin`;
};

export const getFullPath = (language: string, defaultLanguage: string, path: string): string => {
  const pathWithoutLanguage = getPathWithoutLanguage(path);

  const localePathSegment = !language || language === defaultLanguage ? '' : `/${language}`;

  return `${localePathSegment}${pathWithoutLanguage}`;
};

export const hasCorrectPath = (expectedUrl: string, resolvedUrl: string): boolean => {
  // There is a bug either in vercel or next that leads to inconsistent values for resolvedUrl
  // The occurs when we use rewrites for :country or :language
  // In dev, resolvedUrl simply ignores those parameters (doesn't append them to the path)
  // Ex. /:companyId
  // But in production, resolved url returns those as query parameters, leading to a mismatch
  // Ex. /:country/:language/:companyId?country=uy&language=es
  // May be related to this issue: https://github.com/vercel/next.js/issues/22558
  // For now, we will simply remove the query params from the resolvedUrl,
  // but we must be careful not to use this where we actually use query params (like search page)

  // Remove query parameters
  let formattedResolvedUrl = resolvedUrl.split('?')[0];

  // Remove language path segment
  formattedResolvedUrl = formattedResolvedUrl.replace(new RegExp(`^/(${localeSubpathsLanguage.join('/|')})`), '/');

  return formattedResolvedUrl === expectedUrl;
};

export const getSearchPath = (key: string, search: string[]): string | null => {
  if (!search || search?.length === 0) {
    return '';
  }

  if (search?.length > 0 && !key && !['industries', 'locations'].includes(search[0])) {
    return search[0];
  }

  const index = search.indexOf(key);
  if (index !== -1) {
    return search[index + 1];
  }

  return null;
};

export const getParamsFromPath = (key: string, search: string[]): string => {
  const names = getSearchPath(key, search);
  return names ?? '';
};

export const getIdFromSlug = (slug: string): string | null => {
  const names = slug?.split('-');
  return names ? names[names.length - 1] : null;
};

/**
 * Retrieves an array of values corresponding to the given key from the query parameters.
 * @param key - The key to search for in the query parameters.
 * @param queryParams - The set of query parameters to search in.
 * @returns An array of values corresponding to the given key from the query parameters. If the key is not found or the value is falsy, an empty array is returned.
 */
export const getArrayValuesFromQueryParams = (
  key: string,
  queryParams: Record<string, string | string[] | undefined>,
): Array<string> => {
  if (!key || !queryParams) {
    return [];
  }

  const value = queryParams[key];

  if (!value) {
    return [];
  }

  if (typeof value === 'string') {
    return [value];
  }

  return value;
};

export const industryIdIsSector = (industryId: number): boolean => industryId < 100;

export const industryIdIsSic = (industryId: number): boolean => industryId >= 100 && industryId < 99990000;

export const industryIdIsSubject = (industryId: number): boolean => industryId >= 99990000;

export const getBreadCrumbIndustryQuery = (industryId: number) => {
  if (industryIdIsSector(industryId)) return { sectorId: String(industryId) };
  if (industryIdIsSubject(industryId)) return { subjectId: String(industryId) };
  if (industryIdIsSic(industryId)) return { sicId: String(industryId) };
  return {};
};
