import { rest } from 'msw';

export const handlers = [
  rest.get('http://host.docker.internal:4456/dunsguide/companies/3d9a3d0ebcb835b96bc5cea36d80079e', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        id: '3d9a3d0ebcb835b96bc5cea36d80079e',
        legalName: 'Onco Technologies, S.A. de C.V.',
        tradeName: '',
        primaryName: 'Onco Technologies, S.A. de C.V.',
        secondaryName: '',
        countryCode: 'mex',
        location: 'Circuito Economistas-a 31, Naucalpan De Juárez, Estado De México, 53100, México',
        shortLocation: 'Naucalpan De Juárez, Estado De México, México',
        legalEntity: null,
        latitude: 35.0826103,
        longitude: -106.8165629,
        foundingDate: '2013',
        employeeCount: '20 - 50',
        taxId: 'OTE130117UK7',
        slug: 'onco-technologies-sa-de-cv',
        industryCodes: '51220000, 50470300',
        dunsSuffix: '5784',
        createdAt: '2018-09-06T07:33:18.817Z',
        updatedAt: '1753-01-01T00:00:00.000Z',
        logo: '',
        background: '',
        facebook: '',
        instagram: '',
        linkedin: '',
        twitter: '',
        youtube: '',
        googleplus: '',
        website: '',
        isHidden: false,
        hideEmail: false,
        hidePhone: false,
        useTradeNameAsPrimary: false,
        translations: [],
        badges: [],
        description: {},
        phones: [],
        emails: [],
        openingTime: [],
        infographics: [],
        people: [],
        gallery: [],
        plans: [],
        languagesService: [],
        locationsServed: [],
        meta: { contactByEmailEnabled: false },
        industries: [],
        industrySet: { sectors: [], subjects: [], sics: [] },
      }),
    );
  }),
  rest.get(
    'http://host.docker.internal:4456/dunsguide/reviews/companies/3d9a3d0ebcb835b96bc5cea36d80079e/review-statistics',
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json({}));
    },
  ),
  rest.get('http://host.docker.internal:4456/dunsguide/industries/99995594', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        id: 99995594,
        sics: [
          {
            id: '20320000',
            description: { es: 'Enlatado, especialidades' },
            seoDescription: { es: null },
            slug: { es: 'enlatado-especialidades-20320000' },
          },
          {
            id: '20320100',
            description: { es: 'Frijoles y habichuelas en lata, frascos, etc.' },
            seoDescription: { es: null },
            slug: { es: 'frijoles-y-habichuelas-en-lata-frascos-etc--20320100' },
          },
          {
            id: '20320101',
            description: { es: 'Habichuelas en lata, frascos, etc.' },
            seoDescription: { es: null },
            slug: { es: 'habichuelas-en-lata-frascos-etc--20320101' },
          },
          {
            id: '20320102',
            description: { es: 'Frijoles cocinados con carne, en lata, frascos, etc.' },
            seoDescription: { es: null },
            slug: { es: 'frijoles-cocinados-con-carne-en-lata-frascos-etc--20320102' },
          },
          {
            id: '20320103',
            description: { es: 'Frijoles cocinados sin carne, en lata, frascos, etc.' },
            seoDescription: { es: null },
            slug: { es: 'frijoles-cocinados-sin-carne-en-lata-frascos-etc--20320103' },
          },
          {
            id: '20320104',
            description: { es: 'Frijoles con carne, en lata, frascos, etc.' },
            seoDescription: { es: null },
            slug: { es: 'frijoles-con-carne-en-lata-frascos-etc--20320104' },
          },
          {
            id: '20320105',
            description: { es: 'Frijoles sin carne, en lata, frascos, etc.' },
            seoDescription: { es: null },
            slug: { es: 'frijoles-sin-carne-en-lata-frascos-etc--20320105' },
          },
          {
            id: '20320200',
            description: { es: 'Alimentos etnicos, en lata, frascos, etc.' },
            seoDescription: { es: null },
            slug: { es: 'alimentos-etnicos-en-lata-frascos-etc--20320200' },
          },
          {
            id: '20320201',
            description: { es: 'Chile, con o sin carne, en lata, frascos, etc.' },
            seoDescription: { es: null },
            slug: { es: 'chile-con-o-sin-carne-en-lata-frascos-etc--20320201' },
          },
          {
            id: '20320202',
            description: { es: 'Comida china, en lata, frascos, etc.' },
            seoDescription: { es: null },
            slug: { es: 'comida-china-en-lata-frascos-etc--20320202' },
          },
          {
            id: '20320203',
            description: { es: 'Chop suey en lata, frascos, etc.' },
            seoDescription: { es: null },
            slug: { es: 'chop-suey-en-lata-frascos-etc--20320203' },
          },
          {
            id: '20320204',
            description: { es: 'Chow mein en lata, frascos, etc.' },
            seoDescription: { es: null },
            slug: { es: 'chow-mein-en-lata-frascos-etc--20320204' },
          },
          {
            id: '20320205',
            description: { es: 'Enchiladas en lata, frascos, etc.' },
            seoDescription: { es: null },
            slug: { es: 'enchiladas-en-lata-frascos-etc--20320205' },
          },
          {
            id: '20320206',
            description: { es: 'Comida italiana, en lata, frascos, etc.' },
            seoDescription: { es: null },
            slug: { es: 'comida-italiana-en-lata-frascos-etc--20320206' },
          },
          {
            id: '20320207',
            description: { es: 'Macarron en lata, frascos, etc.' },
            seoDescription: { es: null },
            slug: { es: 'macarron-en-lata-frascos-etc--20320207' },
          },
          {
            id: '20320208',
            description: { es: 'Comida mexicana en lata, frascos, etc.' },
            seoDescription: { es: null },
            slug: { es: 'comida-mexicana-en-lata-frascos-etc--20320208' },
          },
          {
            id: '20320209',
            description: { es: 'Ravioles en lata, frascos, etc.' },
            seoDescription: { es: null },
            slug: { es: 'ravioles-en-lata-frascos-etc--20320209' },
          },
          {
            id: '20320210',
            description: { es: 'Spaghetti en lata, frascos, etc.' },
            seoDescription: { es: null },
            slug: { es: 'spaghetti-en-lata-frascos-etc--20320210' },
          },
          {
            id: '20320211',
            description: { es: 'Comida espanola en lata, frascos, etc.' },
            seoDescription: { es: null },
            slug: { es: 'comida-espanola-en-lata-frascos-etc--20320211' },
          },
          {
            id: '20320212',
            description: { es: 'Tamales en lata, frascos, etc.' },
            seoDescription: { es: null },
            slug: { es: 'tamales-en-lata-frascos-etc--20320212' },
          },
        ],
        description: { en: 'Canned', es: 'Enlatado', pt: 'Enlatados' },
        seoDescription: { en: null, es: null, pt: null },
        slug: { en: 'canned-99995594', es: 'enlatado-99995594', pt: 'enlatados-99995594' },
      }),
    );
  }),
  rest.get('http://host.docker.internal:4456/dunsguide/industries/99995588', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        id: 99995588,
        sics: [
          {
            id: '59639904',
            description: { es: 'Ventas directas por telefono' },
            seoDescription: { es: null },
            slug: { es: 'ventas-directas-por-telefono-59639904' },
          },
        ],
        description: {
          en: 'Call centers',
          es: 'Centros de atención al cliente',
          pt: 'Centros de atendimento ao cliente',
        },
        seoDescription: { en: null, es: null, pt: null },
        slug: {
          en: 'call-centers-99995588',
          es: 'centros-de-atencion-al-cliente-99995588',
          pt: 'centros-de-atendimento-ao-cliente-99995588',
        },
      }),
    );
  }),
  rest.get('http://host.docker.internal:4456/dunsguide/industries/19', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        id: 19,
        subjects: [],
        description: {
          en: 'Public Administration',
          es: 'Administración Pública',
          pt: 'Administração pública',
        },
        seoDescription: { en: null, es: null, pt: null },
        slug: {
          en: 'public-administration-19',
          es: 'administracion-publica-19',
          pt: 'administracao-publica-19',
        },
      }),
    );
  }),
  rest.get('http://host.docker.internal:4456/dunsguide/industries/99996057', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        id: 99996057,
        sics: [
          {
            id: '91110000',
            description: {
              en: 'Executive offices',
            },
            seoDescription: {
              en: null,
            },
            slug: {
              en: 'executive-offices-91110000',
            },
          },
          {
            id: '91110100',
            description: {
              en: 'Executive offices, national',
            },
            seoDescription: {
              en: null,
            },
            slug: {
              en: 'executive-offices-national-91110100',
            },
          },
          {
            id: '91110101',
            description: {
              en: 'Executive advisory commission',
            },
            seoDescription: {
              en: null,
            },
            slug: {
              en: 'executive-advisory-commission-91110101',
            },
          },
          {
            id: '91110102',
            description: {
              en: "Presidents' office",
            },
            seoDescription: {
              en: null,
            },
            slug: {
              en: 'presidents-office-91110102',
            },
          },
          {
            id: '91110200',
            description: {
              en: 'Executive offices, state and local',
            },
            seoDescription: {
              en: null,
            },
            slug: {
              en: 'executive-offices-state-and-local-91110200',
            },
          },
          {
            id: '91110201',
            description: {
              en: "City and town managers' office",
            },
            seoDescription: {
              en: null,
            },
            slug: {
              en: 'city-and-town-managers-office-91110201',
            },
          },
          {
            id: '91110202',
            description: {
              en: "County supervisors' and executives' office",
            },
            seoDescription: {
              en: null,
            },
            slug: {
              en: 'county-supervisors-and-executives-office-91110202',
            },
          },
          {
            id: '91110203',
            description: {
              en: "Governors' office",
            },
            seoDescription: {
              en: null,
            },
            slug: {
              en: 'governors-office-91110203',
            },
          },
          {
            id: '91110204',
            description: {
              en: "Mayors' office",
            },
            seoDescription: {
              en: null,
            },
            slug: {
              en: 'mayors-office-91110204',
            },
          },
          {
            id: '91990000',
            description: {
              en: 'General government, nec',
            },
            seoDescription: {
              en: null,
            },
            slug: {
              en: 'general-government-nec-91990000',
            },
          },
          {
            id: '91990100',
            description: {
              en: 'General government administration',
            },
            seoDescription: {
              en: null,
            },
            slug: {
              en: 'general-government-administration-91990100',
            },
          },
          {
            id: '91990101',
            description: {
              en: 'General accounting office, government',
            },
            seoDescription: {
              en: null,
            },
            slug: {
              en: 'general-accounting-office-government-91990101',
            },
          },
          {
            id: '91990102',
            description: {
              en: 'Personnel agency, government',
            },
            seoDescription: {
              en: null,
            },
            slug: {
              en: 'personnel-agency-government-91990102',
            },
          },
          {
            id: '91990103',
            description: {
              en: 'Purchasing agency, government',
            },
            seoDescription: {
              en: null,
            },
            slug: {
              en: 'purchasing-agency-government-91990103',
            },
          },
          {
            id: '91990104',
            description: {
              en: 'Supply agency, government',
            },
            seoDescription: {
              en: null,
            },
            slug: {
              en: 'supply-agency-government-91990104',
            },
          },
          {
            id: '91999901',
            description: {
              en: 'Civil rights commission, government',
            },
            seoDescription: {
              en: null,
            },
            slug: {
              en: 'civil-rights-commission-government-91999901',
            },
          },
          {
            id: '91999902',
            description: {
              en: 'Civil service commission, government',
            },
            seoDescription: {
              en: null,
            },
            slug: {
              en: 'civil-service-commission-government-91999902',
            },
          },
          {
            id: '93110201',
            description: {
              en: 'Budget agency, government',
            },
            seoDescription: {
              en: null,
            },
            slug: {
              en: 'budget-agency-government-93110201',
            },
          },
          {
            id: '93110205',
            description: {
              en: "Treasurers' office, government",
            },
            seoDescription: {
              en: null,
            },
            slug: {
              en: 'treasurers-office-government-93110205',
            },
          },
          {
            id: '95110000',
            description: {
              en: 'Air, water, and solid waste management',
            },
            seoDescription: {
              en: null,
            },
            slug: {
              en: 'air-water-and-solid-waste-management-95110000',
            },
          },
        ],
        description: {
          en: 'Government Offices',
          es: 'Oficinas de gobierno',
          pt: 'Escritórios Governamentais',
        },
        seoDescription: {
          en: null,
          es: null,
          pt: null,
        },
        slug: {
          en: 'government-offices-99996057',
          es: 'oficinas-de-gobierno-99996057',
          pt: 'escritorios-governamentais-99996057',
        },
      }),
    );
  }),
  rest.get('http://host.docker.internal:4456/dunsguide/industries/91999901', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        id: '91999901',
        description: {
          en: 'Civil rights commission, government',
          es: 'Comision de los derechos civiles, del gobierno',
          pt: 'Comissão de direitos civis, governo',
        },
        seoDescription: {
          en: null,
          es: null,
          pt: null,
        },
        slug: {
          en: 'civil-rights-commission-government-91999901',
          es: 'comision-de-los-derechos-civiles-del-gobierno-91999901',
          pt: 'comissao-de-direitos-civis-governo-91999901',
        },
      }),
    );
  }),
  rest.get('http://host.docker.internal:4456/dunsguide/locations/1', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        id: 1,
        type: 'country',
        indexedCompanies: null,
        centerPoint: null,
        country: 76,
        children: [],
        description: { en: 'Brazil', pt: 'Brasil', es: 'Brasil' },
        fullName: { en: 'Brazil', pt: 'Brasil', es: 'Brasil' },
        slug: { en: 'brazil', es: 'brasil', pt: 'brasil' },
      }),
    );
  }),
  rest.get('http://host.docker.internal:4456/dunsguide/locations/50397', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        id: 50397,
        type: 'city',
        indexedCompanies: null,
        centerPoint: null,
        country: 32,
        children: [],
        description: {
          en: 'Mar del Plata',
        },
        fullName: {
          en: 'Mar del Plata, Buenos Aires, Argentina',
        },
        slug: {
          en: 'mar-del-plata-buenos-aires-argentina',
          es: 'mar-del-plata-buenos-aires-argentina',
          pt: 'mar-del-plata-buenos-aires-argentina',
        },
      }),
    );
  }),

  rest.get('http://host.docker.internal:4456/dunsguide/locations', (req, res, ctx) => {
    const { searchParams } = new URL(req.url);

    if (searchParams.has('slug') && searchParams.get('slug') === 'my-invalid-location') {
      return res(ctx.status(404));
    }

    if (searchParams.has('slug') && (searchParams.get('slug') === 'brazil' || searchParams.get('slug') === 'brasil')) {
      return res(
        ctx.status(200),
        ctx.json([
          {
            id: 1,
            indexedCompanies: null,
            type: 'country',
            country: 76,
            description: { es: 'Brasil', pt: 'Brasil', en: 'Brazil' },
            fullName: { es: 'Brasil', pt: 'Brasil', en: 'Brazil' },
            slug: { en: 'brazil', es: 'brasil', pt: 'brasil' },
          },
        ]),
      );
    }

    if (searchParams.has('slug') && searchParams.get('slug') === 'mar-del-plata-buenos-aires-argentina') {
      return res(
        ctx.status(200),
        ctx.json([
          {
            id: 50397,
            type: 'city',
            indexedCompanies: null,
            centerPoint: null,
            country: 32,
            children: [],
            description: {
              en: 'Mar del Plata',
            },
            fullName: {
              en: 'Mar del Plata, Buenos Aires, Argentina',
            },
            slug: {
              en: 'mar-del-plata-buenos-aires-argentina',
            },
          },
        ]),
      );
    }

    if (searchParams.has('oldId') && searchParams.get('oldId') === '6' && searchParams.get('type') === 'state') {
      return res(
        ctx.status(200),
        ctx.json([
          {
            id: 1,
            indexedCompanies: null,
            type: 'country',
            country: 76,
            description: { en: 'Brazil', pt: 'Brasil', es: 'Brasil' },
            fullName: { en: 'Brazil', pt: 'Brasil', es: 'Brasil' },
            slug: { en: 'brazil', es: 'brasil', pt: 'brasil' },
          },
          {
            id: 2,
            indexedCompanies: null,
            type: 'state',
            country: 76,
            description: {
              en: 'Rio Grande do Norte',
              es: 'Rio Grande do Norte',
              pt: 'Rio Grande do Norte',
              he: 'Rio Grande do Norte',
            },
            fullName: {
              en: 'Rio Grande do Norte, Brazil',
              es: 'Rio Grande do Norte, Brasil',
              pt: 'Rio Grande do Norte, Brasil',
              he: 'Rio Grande do Norte, Brazil',
            },
            slug: {
              en: 'rio-grande-do-norte-brazil',
              es: 'rio-grande-do-norte-brasil',
              he: 'rio-grande-do-norte-brazil',
              pt: 'rio-grande-do-norte-brasil',
            },
          },
        ]),
      );
    }

    if (searchParams.has('oldId') && (searchParams.get('oldId') === '6' || searchParams.get('type') === 'city')) {
      return res(
        ctx.status(200),
        ctx.json([
          {
            id: 1,
            indexedCompanies: null,
            type: 'city',
            country: 76,
            description: { en: 'Zumbi', es: 'Zumbi', pt: 'Zumbi', he: 'Zumbi' },
            fullName: {
              en: 'Zumbi, Rio Grande do Norte, Brazil',
              es: 'Zumbi, Rio Grande do Norte, Brasil',
              pt: 'Zumbi, Rio Grande do Norte, Brasil',
              he: 'Zumbi, Rio Grande do Norte, Brazil',
            },
            slug: {
              en: 'zumbi-rio-grande-do-norte-brazil',
              es: 'zumbi-rio-grande-do-norte-brasil',
              he: 'zumbi-rio-grande-do-norte-brazil',
              pt: 'zumbi-rio-grande-do-norte-brasil',
            },
          },
          {
            id: 4,
            indexedCompanies: null,
            type: 'city',
            country: 76,
            description: { en: 'Zé Araújo', es: 'Zé Araújo', pt: 'Zé Araújo', he: 'Zé Araújo' },
            fullName: {
              en: 'Zé Araújo, Rio Grande do Norte, Brazil',
              es: 'Zé Araújo, Rio Grande do Norte, Brasil',
              pt: 'Zé Araújo, Rio Grande do Norte, Brasil',
              he: 'Zé Araújo, Rio Grande do Norte, Brazil',
            },
            slug: {
              en: 'ze-araujo-rio-grande-do-norte-brazil',
              es: 'ze-araujo-rio-grande-do-norte-brasil',
              he: 'ze-araujo-rio-grande-do-norte-brazil',
              pt: 'ze-araujo-rio-grande-do-norte-brasil',
            },
          },
        ]),
      );
    }
  }),

  rest.get('http://host.docker.internal:4456/dunsguide/search/breadcrumbs', (req, res, ctx) => {
    const { searchParams } = new URL(req.url);

    if (
      searchParams.has('sectorId') &&
      searchParams.get('sectorId') === '19' &&
      searchParams.has('locationId') &&
      searchParams.get('locationId') === 'brazil'
    ) {
      return res(
        ctx.status(200),
        ctx.json([
          {
            label: 'Brazil',
            url: '/locations/brazil',
          },
          {
            label: 'Public Administration',
            url: '/industries/public-administration-19',
          },
        ]),
      );
    }
    if (searchParams.has('sectorId') && searchParams.get('sectorId') === '19') {
      return res(
        ctx.status(200),
        ctx.json([{ label: 'Public Administration', url: '/industries/public-administration-19' }]),
      );
    }
    if (searchParams.has('locationId') && searchParams.get('locationId') === 'brazil') {
      return res(ctx.status(200), ctx.json([{ label: 'Brazil', url: '/locations/brazil' }]));
    }

    if (
      searchParams.has('sectorId') &&
      searchParams.get('sectorId') === '49250105' &&
      searchParams.has('locationSlug') &&
      searchParams.get('locationSlug') === 'mar-del-plata-buenos-aires-argentina'
    ) {
      return res(
        ctx.status(200),
        ctx.json([
          {
            label: 'Argentina',
            url: '/companies/locations/argentina',
          },
          {
            label: 'Buenos Aires',
            url: '/companies/locations/buenos-aires-argentina',
          },
          {
            label: 'Mar del Plata',
            url: '/companies/locations/mar-del-plata-buenos-aires-argentina',
          },
          {
            label: 'Public Administration',
            url: '/companies/locations/mar-del-plata-buenos-aires-argentina/industries/public-administration-19',
          },
          {
            label: 'Government Offices',
            url: '/companies/locations/mar-del-plata-buenos-aires-argentina/industries/government-offices-99996057?sectorId=19',
          },
          {
            label: 'Civil rights commission, government',
            url: '/companies/locations/mar-del-plata-buenos-aires-argentina/industries/civil-rights-commission-government-91999901?sectorId=19&subjectId=99996057',
          },
        ]),
      );
    }
    if (searchParams.has('sectorId') && searchParams.get('sectorId') === '91999901') {
      return res(
        ctx.status(200),
        ctx.json([
          {
            label: 'Public Administration',
            url: '/companies/locations/mar-del-plata-buenos-aires-argentina/industries/public-administration-19',
          },
          {
            label: 'Government Offices',
            url: '/companies/locations/mar-del-plata-buenos-aires-argentina/industries/government-offices-99996057?sectorId=19',
          },
          {
            label: 'Civil rights commission, government',
            url: '/companies/locations/mar-del-plata-buenos-aires-argentina/industries/civil-rights-commission-government-91999901?sectorId=19&subjectId=99996057',
          },
        ]),
      );
    }
    if (
      searchParams.has('locationSlug') &&
      searchParams.get('locationSlug') === 'mar-del-plata-buenos-aires-argentina'
    ) {
      return res(
        ctx.status(200),
        ctx.json([
          {
            label: 'Argentina',
            url: '/companies/locations/argentina',
          },
          {
            label: 'Buenos Aires',
            url: '/companies/locations/buenos-aires-argentina',
          },
          {
            label: 'Mar del Plata',
            url: '/companies/locations/mar-del-plata-buenos-aires-argentina',
          },
        ]),
      );
    }

    return res(ctx.status(404));
  }),

  rest.get('http://host.docker.internal:4456/dunsguide/search', (req, res, ctx) => {
    const { searchParams } = new URL(req.url);
    if (searchParams.has('industrySlug') && searchParams.get('industrySlug') === 'public-administration-19') {
      return res(ctx.status(200), ctx.json({ data: [], searchId: '1234556789', count: 0 }));
    }
    if (searchParams.has('locationSlug') && searchParams.get('locationSlug') === 'brazil') {
      return res(
        ctx.status(200),
        ctx.json({
          data: [
            {
              id: 'b99e436f4ba10a2bc185c35a005bfda2',
              legalName: 'Zopone Engenharia e Comercio Ltda',
              tradeName: '',
              primaryName: 'Zopone Engenharia e Comercio Ltda',
              secondaryName: '',
              countryCode: 'bra',
              location: 'Rodrigues Alves 34-53, 17030000, Vila Coralina, Bauru, São Paulo, Brazil',
              shortLocation: 'Bauru, São Paulo, Brazil',
              legalEntity: null,
              latitude: 35.0826103,
              longitude: -106.8165629,
              foundingDate: '1988',
              taxId: '59225698000196',
              slug: 'zopone-engenharia-e-comercio-ltda',
              industryCodes: '15419905, 4120400',
              dunsSuffix: '2461',
              createdAt: '2023-08-22T09:46:45.232Z',
              updatedAt: '2023-08-22T09:46:45.232Z',
              logo: null,
              background: null,
              facebook: null,
              instagram: null,
              linkedin: null,
              twitter: null,
              youtube: null,
              googleplus: null,
              website: null,
              isHidden: false,
              hideEmail: false,
              hidePhone: false,
              useTradeNameAsPrimary: false,
              translations: [],
              badges: [],
              description: {},
              infographics: [],
              meta: {
                contactByEmailEnabled: false,
              },
              locationsServed: [],
            },
          ],
          searchId: '1234556789',
          count: 10000,
        }),
      );
    }
    return res(ctx.status(404));
  }),

  rest.get('http://host.docker.internal:4456/dunsguide/locations/all', (req, res, ctx) => {
    const { searchParams } = new URL(req.url);
    if (searchParams.has('slug') && searchParams.get('slug') === 'brazil') {
      return res(
        ctx.status(200),
        ctx.json({
          results: [
            {
              id: 1,
              type: 'country',
              indexedCompanies: null,
              centerPoint: null,
              country: 76,
              children: [
                {
                  id: 44653,
                  type: 'state',
                  indexedCompanies: null,
                  centerPoint: null,
                  country: 76,
                  description: {
                    en: 'Acre',
                  },
                  fullName: {
                    en: 'Acre, Brazil',
                  },
                  slug: {
                    en: 'acre-brazil',
                  },
                },
                {
                  id: 13163,
                  type: 'state',
                  indexedCompanies: null,
                  centerPoint: null,
                  country: 76,
                  description: {
                    en: 'Alagoas',
                  },
                  fullName: {
                    en: 'Alagoas, Brazil',
                  },
                  slug: {
                    en: 'alagoas-brazil',
                  },
                },
                {
                  id: 12928,
                  type: 'state',
                  indexedCompanies: null,
                  centerPoint: null,
                  country: 76,
                  description: {
                    en: 'Amapá',
                  },
                  fullName: {
                    en: 'Amapá, Brazil',
                  },
                  slug: {
                    en: 'amapa-brazil',
                  },
                },
                {
                  id: 43166,
                  type: 'state',
                  indexedCompanies: null,
                  centerPoint: null,
                  country: 76,
                  description: {
                    en: 'Amazonas',
                  },
                  fullName: {
                    en: 'Amazonas, Brazil',
                  },
                  slug: {
                    en: 'amazonas-brazil',
                  },
                },
                {
                  id: 39185,
                  type: 'state',
                  indexedCompanies: null,
                  centerPoint: null,
                  country: 76,
                  description: {
                    en: 'Bahia',
                  },
                  fullName: {
                    en: 'Bahia, Brazil',
                  },
                  slug: {
                    en: 'bahia-brazil',
                  },
                },
              ],
              description: {
                en: 'Brazil',
              },
              fullName: {
                en: 'Brazil',
              },
              slug: {
                en: 'brazil',
              },
            },
          ],
          total: 1,
          page: '1',
          totalPages: 1,
          pageTotal: '1',
        }),
      );
    }

    if (searchParams.has('slug') && searchParams.get('slug') === 'mar-del-plata-buenos-aires-argentina') {
      return res(
        ctx.status(200),
        ctx.json({
          results: [
            {
              id: 50397,
              type: 'city',
              indexedCompanies: null,
              centerPoint: null,
              country: 32,
              children: [],
              description: {
                en: 'Mar del Plata',
              },
              fullName: {
                en: 'Mar del Plata, Buenos Aires, Argentina',
              },
              slug: {
                en: 'mar-del-plata-buenos-aires-argentina',
                es: 'mar-del-plata-buenos-aires-argentina',
                pt: 'mar-del-plata-buenos-aires-argentina',
              },
            },
          ],
        }),
      );
    }

    return res(ctx.status(404));
  }),

  rest.get('/api/api/users/me', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        id: '7c8434cc-b951-4AAA-9dd4-de29d5be445a',
        firstName: 'Bob',
        lastName: 'Smith',
        name: 'Jorge De Buono',
        title: 'Co-founder & Sales Director',
        image: '/people/1612495735_367uFgupUh.jpg',
        memberSince: '2021-06-11T02:48:24.370Z',
        companies: [
          {
            logo: '/logos/1611083138_iVku6NGQWP.JPG',
            primaryName: 'ACTIVESEC S.A.',
            id: '55179d1e839c10180b059b07cc40d945',
            slug: 'activesec-sa',
          },
          {
            logo: '/logos/1611083138_iVku6NGQWP.JPG',
            primaryName: 'ACTIVESEC S.A.',
            id: '3f590cf44bf496996fb3e9a766250bee',
            slug: 'activesec-sa',
          },
        ],
        plan: {
          currentPlan: 'silver',
          order: 2,
        },
        reviews: [
          {
            review:
              'The hardware is nice, but i really don’t like their closed off app store. Also they are really expensive. But I have no other choice because once you buy one thing, you need to buy them all.',
            rating: 4,
            companyName: 'Apple Inc.',
            createdAt: '2021-06-11T02:49:03.697Z',
            logo: '/logos/1611083138_iVku6NGQWP.JPG',
            status: 'pending',
          },
          {
            review: 'People still buy these things?',
            rating: 3,
            companyName: 'Dell Computers',
            createdAt: '2021-06-11T02:49:03.697Z',
            logo: '/logos/1611083138_iVku6NGQWP.JPG',
            status: 'approved',
          },
          {
            review: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut,enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut,enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
            rating: 2,
            companyName: 'Apple Inc.',
            createdAt: '2021-06-11T02:49:03.697Z',
            logo: '/logos/1611083138_iVku6NGQWP.JPG',
            status: 'rejected',
          },
          {
            review: '...',
            rating: 5,
            companyName: 'Dell Computers',
            createdAt: '2021-06-11T02:49:03.697Z',
            logo: '/logos/1611083138_iVku6NGQWP.JPG',
          },
        ],
        favorites: [
          {
            id: '3c90f1b1471e542fa54e2eaf98884fdf',
            logo: '/logos/1611083138_iVku6NGQWP.JPG',
            companyName: 'Productora Agrícola de Cítricos Potosinos, S. de R.L. de C.V.',
            slug: 'productora-agricola-de-citricos-potosinos-s-de-rl-de-cv',
          },
          {
            id: 'ffea8356343cd2f6b7e47d13234dc0b5',
            logo: '/logos/1563901974_Bl6MGuuFmD.jpg',
            companyName: 'ACTIVESEC S.A.',
            slug: 'activesec-sa',
          },
        ],
      }),
    );
  }),

  /*rest.get(apiUrl('/favorites/list'), (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        data: [
          {
            id: '3c90f1b1471e542fa54e2eaf98884fdf',
            logo: '/logos/1611083138_iVku6NGQWP.JPG',
            companyName: 'Productora Agrícola de Cítricos Potosinos, S. de R.L. de C.V.',
            slug: 'productora-agricola-de-citricos-potosinos-s-de-rl-de-cv',
          },
          {
            id: 'ffea8356343cd2f6b7e47d13234dc0b5',
            logo: '/logos/1563901974_Bl6MGuuFmD.jpg',
            companyName: 'ACTIVESEC S.A.',
            slug: 'activesec-sa',
          },
        ],
      }),
    );
  }),
  rest.get(apiUrl('/favorites/ids'), (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        data: ['3c90f1b1471e542fa54e2eaf98884fdf', 'ffea8356343cd2f6b7e47d13234dc0b5'],
      }),
    );
  }),*/
];
