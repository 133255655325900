import React, { createContext, useState } from 'react';

import { get } from 'lodash';
import PropTypes from 'prop-types';

import { getPathWithoutLanguage } from 'utils/countryRoute';

export const RouterContext = createContext();

const propTypes = {
  router: PropTypes.object,
  languageParam: PropTypes.string,
  children: PropTypes.node.isRequired,
};

const RouterContextProvider = ({ router, languageParam = '', children }) => {
  const [state, setState] = useState({});

  const getLanguagePathSegment = (locale = true) => {
    // Locale option is meant to work similarly to next's Link locale option
    if (!locale.length && (!locale || !languageParam)) return '';

    return locale.length ? `/${locale}` : `/${languageParam}`;
  };

  const getLocalizedPath = (path, locale) => {
    // Allows us to pass path with or without language
    const pathWithoutLanguage = getPathWithoutLanguage(path);
    return `${getLanguagePathSegment(locale)}${pathWithoutLanguage}`;
  };

  const push = (url, as, options) => {
    const urlPathName = get(url, 'pathname') || url;
    const asRoute = as || urlPathName;

    // This allows for passing transient state (options.state) during route transitions,
    // mimicking React Router's behavior of handling navigation state without needing to persist it in the URL.
    // Set state if provided, or reset the state if not
    if (options?.state) {
      setState(options.state);
    } else {
      setState({});
    }

    router.push({ ...url, pathname: getLocalizedPath(asRoute) }, as !== undefined ? getLocalizedPath(as) : as, options);
  };

  return (
    <RouterContext.Provider
      value={{
        router: {
          ...router,
          push,
          state,
        },
        getLocalizedPath,
      }}
    >
      {children}
    </RouterContext.Provider>
  );
};

RouterContextProvider.propTypes = propTypes;

export default RouterContextProvider;
