// eslint-disable-next-line @typescript-eslint/no-var-requires
const { includes } = require('lodash');

if (typeof Cypress !== 'undefined') {
  // So we can use this file within cypress
  // eslint-disable-next-line no-undef
  process.env = Cypress.env();
}

const languageMeta = {
  en: {
    value: 'en',
    label: 'English',
    direction: 'ltr',
  },
  es: {
    value: 'es',
    label: 'Spanish',
    direction: 'ltr',
  },
  pt: {
    value: 'pt',
    label: 'Portuguese',
    direction: 'ltr',
  },
  he: {
    value: 'he',
    label: 'Hebrew',
    direction: 'rtl',
  },
};

const languageOptions = Object.values(languageMeta);

const localeSubpaths = {
  en: 'en',
  es: 'es',
  pt: 'pt',
  he: 'he',
};

const countryMeta = {
  bb: {
    lang: 'en',
    country: 'bb',
    iso3: 'brb',
    fullCountry: 'Barbados',
    description: {
      en: 'Barbados',
      es: 'Barbados',
      pt: 'Barbados',
      he: 'ברבדוס',
    },
    supportedLanguages: ['es', 'en', 'pt'],
    slug: {
      en: 'barbados',
      es: 'barbados',
      pt: 'barbados',
    },
    numberFormatter: { lang: 'en-BB', currency: 'BBD' }, // Barbadian Dollar
  },
  cl: {
    lang: 'es',
    country: 'cl',
    iso3: 'chl',
    fullCountry: 'Chile',
    description: {
      en: 'Chile',
      es: 'Chile',
      pt: 'Chile',
      he: "צ'ילה",
    },
    supportedLanguages: ['es', 'en', 'pt'],
    slug: {
      en: 'chile',
      es: 'chile',
      pt: 'chile',
    },
    numberFormatter: { lang: 'es-CL', currency: 'CLP' }, // Chilean Peso
  },
  cr: {
    lang: 'es',
    country: 'cr',
    iso3: 'cri',
    fullCountry: 'Costa Rica',
    description: {
      en: 'Costa Rica',
      es: 'Costa Rica',
      pt: 'Costa Rica',
      he: 'קוסטה ריקה',
    },
    supportedLanguages: ['es', 'en', 'pt'],
    slug: {
      en: 'costa-rica',
      es: 'costa-rica',
      pt: 'costa-rica',
    },
    numberFormatter: { lang: 'es-CR', currency: 'CRC' }, // Costa Rican Colón
  },
  do: {
    lang: 'es',
    country: 'do',
    iso3: 'dom',
    fullCountry: 'Dominican Republic',
    description: {
      en: 'Dominican Republic',
      es: 'República Dominicana',
      pt: 'República Dominicana',
      he: 'הרפובליקה הדומיניקנית',
    },
    supportedLanguages: ['es', 'en', 'pt'],
    slug: {
      en: 'dominican-republic',
      es: 'republica-dominicana',
      pt: 'republica-dominicana',
    },
    numberFormatter: { lang: 'es-DO', currency: 'DOP' }, // Dominican Peso
  },
  ec: {
    lang: 'es',
    country: 'ec',
    iso3: 'ecu',
    fullCountry: 'Ecuador',
    description: {
      en: 'Ecuador',
      es: 'Ecuador',
      pt: 'Equador',
      he: 'אקוודור',
    },
    supportedLanguages: ['es', 'en', 'pt'],
    slug: {
      en: 'ecuador',
      es: 'ecuador',
      pt: 'equador',
    },
    numberFormatter: { lang: 'es-EC', currency: 'USD' }, // United States Dollar (USD is used in Ecuador)
  },
  gt: {
    lang: 'es',
    country: 'gt',
    iso3: 'gtm',
    fullCountry: 'Guatemala',
    description: {
      en: 'Guatemala',
      es: 'Guatemala',
      pt: 'Guatemala',
      he: 'גואטמלה',
    },
    supportedLanguages: ['es', 'en', 'pt'],
    slug: {
      en: 'guatemala',
      es: 'guatemala',
      pt: 'guatemala',
    },
    numberFormatter: { lang: 'es-GT', currency: 'GTQ' }, // Guatemalan Quetzal
  },
  gy: {
    lang: 'en',
    country: 'gy',
    iso3: 'guy',
    fullCountry: 'Guyana',
    description: {
      en: 'Guyana',
      es: 'Guyana',
      pt: 'Guiana',
      he: 'גיאנה',
    },
    supportedLanguages: ['es', 'en', 'pt'],
    slug: {
      en: 'guyana',
      es: 'guyana',
      pt: 'guiana',
    },
    numberFormatter: { lang: 'en-GY', currency: 'GYD' }, // Guyanese Dollar
  },
  hn: {
    lang: 'es',
    country: 'hn',
    iso3: 'hnd',
    fullCountry: 'Honduras',
    description: {
      en: 'Honduras',
      es: 'Honduras',
      pt: 'Honduras',
      he: 'הונדורס',
    },
    supportedLanguages: ['es', 'en', 'pt'],
    slug: {
      en: 'honduras',
      es: 'honduras',
      pt: 'honduras',
    },
    numberFormatter: { lang: 'es-HN', currency: 'HNL' }, // Honduran Lempira
  },
  jm: {
    lang: 'en',
    country: 'jm',
    iso3: 'jam',
    fullCountry: 'Jamaica',
    description: {
      en: 'Jamaica',
      es: 'Jamaica',
      pt: 'Jamaica',
      he: "ג'מייקה",
    },
    supportedLanguages: ['es', 'en', 'pt'],
    slug: {
      en: 'jamaica',
      es: 'jamaica',
      pt: 'jamaica',
    },
    numberFormatter: { lang: 'en-JM', currency: 'JMD' }, // Jamaican Dollar
  },
  ky: {
    lang: 'en',
    country: 'ky',
    iso3: 'cym',
    fullCountry: 'Cayman Islands',
    description: {
      en: 'Cayman Islands',
      es: 'Islas Caimán',
      pt: 'Ilhas Cayman',
      he: 'איי קיימן',
    },
    supportedLanguages: ['es', 'en', 'pt'],
    slug: {
      en: 'cayman-islands',
      es: 'islas-caiman',
      pt: 'ilhas-cayman',
    },
    numberFormatter: { lang: 'en-KY', currency: 'KYD' }, // Cayman Islands Dollar
  },
  pa: {
    lang: 'es',
    country: 'pa',
    iso3: 'pan',
    fullCountry: 'Panama',
    description: {
      en: 'Panama',
      es: 'Panamá',
      pt: 'Panamá',
      he: 'פנמה',
    },
    supportedLanguages: ['es', 'en', 'pt'],
    slug: {
      en: 'panama',
      es: 'panama',
      pt: 'panama',
    },
    numberFormatter: { lang: 'es-PA', currency: 'PAB' }, // Panamanian Balboa
  },
  py: {
    lang: 'es',
    country: 'py',
    iso3: 'pry',
    fullCountry: 'Paraguay',
    description: {
      en: 'Paraguay',
      es: 'Paraguay',
      pt: 'Paraguai',
      he: 'פרגוואי',
    },
    supportedLanguages: ['es', 'en', 'pt'],
    slug: {
      en: 'paraguay',
      es: 'paraguay',
      pt: 'paraguai',
    },
    numberFormatter: { lang: 'es-PY', currency: 'PYG' }, // Paraguayan Guarani
  },
  sv: {
    lang: 'es',
    country: 'sv',
    iso3: 'slv',
    fullCountry: 'El Salvador',
    description: {
      en: 'El Salvador',
      es: 'El Salvador',
      pt: 'El Salvador',
      he: 'אל סלבדור',
    },
    supportedLanguages: ['es', 'en', 'pt'],
    slug: {
      en: 'el-salvador',
      es: 'el-salvador',
      pt: 'el-salvador',
    },
    numberFormatter: { lang: 'es-SV', currency: 'USD' }, // United States Dollar
  },
  tt: {
    lang: 'en',
    country: 'tt',
    iso3: 'tto',
    fullCountry: 'Trinidad and Tobago',
    description: {
      en: 'Trinidad and Tobago',
      es: 'Trinidad y Tobago',
      pt: 'Trinidad e Tobago',
      he: 'טרינידד וטובגו',
    },
    supportedLanguages: ['es', 'en', 'pt'],
    slug: {
      en: 'trinidad-and-tobago',
      es: 'trinidad-y-tobago',
      pt: 'trindade-e-tobago',
    },
    numberFormatter: { lang: 'en-TT', currency: 'TTD' }, // Trinidad and Tobago Dollar
  },
  uy: {
    lang: 'es',
    country: 'uy',
    iso3: 'ury',
    fullCountry: 'Uruguay',
    description: {
      en: 'Uruguay',
      es: 'Uruguay',
      pt: 'Uruguai',
      he: 'אורוגוואי',
    },
    supportedLanguages: ['es', 'en', 'pt'],
    slug: {
      en: 'uruguay',
      es: 'uruguay',
      pt: 'uruguai',
    },
    numberFormatter: { lang: 'es-UY', currency: 'UYU' }, // Uruguayan Peso
  },
  ve: {
    lang: 'es',
    country: 've',
    iso3: 'ven',
    fullCountry: 'Venezuela',
    description: {
      en: 'Venezuela',
      es: 'Venezuela',
      pt: 'Venezuela',
      he: 'ונצואלה',
    },
    supportedLanguages: ['es', 'en', 'pt'],
    slug: {
      en: 'venezuela',
      es: 'venezuela',
      pt: 'venezuela',
    },
    numberFormatter: { lang: 'es-VE', currency: 'VES' }, // Venezuelan Bolívar
  },
  ai: {
    lang: 'en',
    country: 'ai',
    iso3: 'aia',
    fullCountry: 'Anguilla',
    description: {
      en: 'Anguilla',
      es: 'Anguila',
      pt: 'Anguila',
      he: 'אנגילה',
    },
    supportedLanguages: ['es', 'en', 'pt'],
    slug: {
      en: 'anguilla',
      es: 'anguilla',
      pt: 'anguila',
    },
    numberFormatter: { lang: 'en-AI', currency: 'XCD' }, // East Caribbean Dollar
  },
  ag: {
    lang: 'en',
    country: 'ag',
    iso3: 'atg',
    fullCountry: 'Antigua and Barbuda',
    description: {
      en: 'Antigua and Barbuda',
      es: 'Antigua y Barbuda',
      pt: 'Antígua e Barbuda',
      he: 'אנטיגואה וברבודה',
    },
    supportedLanguages: ['es', 'en', 'pt'],
    slug: {
      en: 'antigua-and-barbuda',
      es: 'antigua-y-barbuda',
      pt: 'antigua-e-barbuda',
    },
    numberFormatter: { lang: 'en-AG', currency: 'XCD' }, // East Caribbean Dollar
  },
  aw: {
    lang: 'en',
    country: 'aw',
    iso3: 'abw',
    fullCountry: 'Aruba',
    description: {
      en: 'Aruba',
      es: 'Aruba',
      pt: 'Aruba',
      he: 'ארובה',
    },
    supportedLanguages: ['es', 'en', 'pt'],
    slug: {
      en: 'aruba',
      es: 'aruba',
      pt: 'aruba',
    },
    numberFormatter: { lang: 'en-AW', currency: 'AWG' }, // Aruban Florin
  },
  bz: {
    lang: 'en',
    country: 'bz',
    iso3: 'blz',
    fullCountry: 'Belize',
    description: {
      en: 'Belize',
      es: 'Belice',
      pt: 'Belize',
      he: 'בליז',
    },
    supportedLanguages: ['es', 'en', 'pt'],
    slug: {
      en: 'belize',
      es: 'belice',
      pt: 'belize',
    },
    numberFormatter: { lang: 'en-BZ', currency: 'BZD' }, // Belize Dollar
  },
  bm: {
    lang: 'en',
    country: 'bm',
    iso3: 'bmu',
    fullCountry: 'Bermuda',
    description: {
      en: 'Bermuda',
      es: 'Bermudas',
      pt: 'Bermudas',
      he: 'ברמודה',
    },
    supportedLanguages: ['es', 'en', 'pt'],
    slug: {
      en: 'bermuda',
      es: 'bermudas',
      pt: 'bermudas',
    },
    numberFormatter: { lang: 'en-BM', currency: 'BMD' }, // Bermudian Dollar
  },
  bo: {
    lang: 'es',
    country: 'bo',
    iso3: 'bol',
    fullCountry: 'Bolivia',
    description: {
      en: 'Bolivia',
      es: 'Bolivia',
      pt: 'Bolívia',
      he: 'בוליביה',
    },
    supportedLanguages: ['es', 'en', 'pt'],
    slug: {
      en: 'bolivia',
      es: 'bolivia',
      pt: 'bolivia',
    },
    numberFormatter: { lang: 'es-BO', currency: 'BOB' }, // Bolivian Boliviano
  },
  bq: {
    lang: 'en',
    country: 'bq',
    iso3: 'bes',
    fullCountry: 'Bonaire',
    description: {
      en: 'Bonaire',
      es: 'Bonaire',
      pt: 'Bonaire',
      he: 'בונייר',
    },
    supportedLanguages: ['es', 'en', 'pt'],
    slug: {
      en: 'bonaire-sint-eustatius-and-saba',
      es: 'bonaire-san-eustaquio-y-saba',
      pt: 'bonaire',
    },
    numberFormatter: { lang: 'en-BQ', currency: 'USD' }, // US Dollar
  },
  cw: {
    lang: 'en',
    country: 'cw',
    iso3: 'cuw',
    fullCountry: 'Curaçao',
    description: {
      en: 'Curaçao',
      es: 'Curazao',
      pt: 'Curaçao',
      he: 'קוראסאו',
    },
    supportedLanguages: ['es', 'en', 'pt'],
    slug: {
      en: 'curacao',
      es: 'curazao',
      pt: 'curacao',
    },
    numberFormatter: { lang: 'en-CW', currency: 'ANG' }, // Netherlands Antillean Guilder
  },
  dm: {
    lang: 'en',
    country: 'dm',
    iso3: 'dma',
    fullCountry: 'Dominica',
    description: {
      en: 'Dominica',
      es: 'Dominica',
      pt: 'Dominica',
      he: 'דומיניקה',
    },
    supportedLanguages: ['es', 'en', 'pt'],
    slug: {
      en: 'dominica',
      es: 'dominica',
      pt: 'dominica',
    },
    numberFormatter: { lang: 'en-DM', currency: 'XCD' }, // East Caribbean Dollar
  },
  gd: {
    lang: 'en',
    country: 'gd',
    iso3: 'grd',
    fullCountry: 'Grenada',
    description: {
      en: 'Grenada',
      es: 'Granada',
      pt: 'Granada',
      he: 'גרנדה',
    },
    supportedLanguages: ['es', 'en', 'pt'],
    slug: {
      en: 'grenada',
      es: 'grenada',
      pt: 'granada',
    },
    numberFormatter: { lang: 'en-GD', currency: 'XCD' }, // East Caribbean Dollar
  },
  ht: {
    lang: 'en',
    country: 'ht',
    iso3: 'hti',
    fullCountry: 'Haiti',
    description: {
      en: 'Haiti',
      es: 'Haití',
      pt: 'Haiti',
      he: 'האיטי',
    },
    supportedLanguages: ['es', 'en', 'pt'],
    slug: {
      en: 'haiti',
      es: 'haiti',
      pt: 'haiti',
    },
    numberFormatter: { lang: 'fr-HT', currency: 'HTG' }, // Haitian Gourde
  },
  an: {
    lang: 'en',
    country: 'an',
    iso3: 'ant',
    fullCountry: 'Netherlands Antilles',
    description: {
      en: 'Netherlands Antilles',
      es: 'Antillas Neerlandesas',
      pt: 'Antilhas Holandesas',
      he: 'אנטילים הולנדיים',
    },
    supportedLanguages: ['es', 'en', 'pt'],
    slug: {
      en: 'netherlands-antilles',
      es: 'antillas-neerlandesas',
      pt: 'antilhas-holandesas',
    },
    numberFormatter: { lang: 'nl-AN', currency: 'ANG' }, // Netherlands Antillean Guilder
  },
  kn: {
    lang: 'en',
    country: 'kn',
    iso3: 'kna',
    fullCountry: 'Saint Kitts and Nevis',
    description: {
      en: 'Saint Kitts and Nevis',
      es: 'San Cristóbal y Nieves',
      pt: 'São Cristóvão e Névis',
      he: 'סנט קיטס ונוויס',
    },
    supportedLanguages: ['es', 'en', 'pt'],
    slug: {
      en: 'saint-kitts-and-nevis',
      es: 'san-cristobal-y-nieves',
      pt: 'sao-cristovao-e-neves',
    },
    numberFormatter: { lang: 'en-KN', currency: 'XCD' }, // East Caribbean Dollar
  },
  lc: {
    lang: 'en',
    country: 'lc',
    iso3: 'lca',
    fullCountry: 'Saint Lucia',
    description: {
      en: 'Saint Lucia',
      es: 'Santa Lucía',
      pt: 'Santa Lúcia',
      he: 'סנט לוסיה',
    },
    supportedLanguages: ['es', 'en', 'pt'],
    slug: {
      en: 'saint-lucia',
      es: 'santa-lucia',
      pt: 'santa-lucia',
    },
    numberFormatter: { lang: 'en-LC', currency: 'XCD' }, // East Caribbean Dollar
  },
  vc: {
    lang: 'en',
    country: 'vc',
    iso3: 'vct',
    fullCountry: 'Saint Vincent and the Grenadines',
    description: {
      en: 'Saint Vincent and the Grenadines',
      es: 'San Vicente y Las Granadinas',
      pt: 'São Vicente e Granadinas',
      he: 'סנט וינסנט והגרנדינים',
    },
    supportedLanguages: ['es', 'en', 'pt'],
    slug: {
      en: 'saint-vincent-and-the-grenadines',
      es: 'san-vicente-y-granadinas',
      pt: 'sao-vicente-e-granadinas',
    },
    numberFormatter: { lang: 'en-VC', currency: 'XCD' }, // East Caribbean Dollar
  },
  sx: {
    lang: 'en',
    country: 'sx',
    iso3: 'sxm',
    fullCountry: 'Sint Maarten',
    description: {
      en: 'Sint Maarten',
      es: 'Sint Maarten',
      pt: 'Sint Maarten',
      he: 'סינט מארטן',
    },
    supportedLanguages: ['es', 'en', 'pt'],
    slug: {
      en: 'sint-maarten-dutch-part',
      es: 'san-martin',
      pt: 'sao-martinho',
    },
    numberFormatter: { lang: 'en-SX', currency: 'ANG' }, // Netherlands Antillean Guilder
  },
  sr: {
    lang: 'en',
    country: 'sr',
    iso3: 'sur',
    fullCountry: 'Suriname',
    description: {
      en: 'Suriname',
      es: 'Surinam',
      pt: 'Suriname',
      he: 'סורינם',
    },
    supportedLanguages: ['es', 'en', 'pt'],
    slug: {
      en: 'suriname',
      es: 'surinam',
      pt: 'suriname',
    },
    numberFormatter: { lang: 'nl-SR', currency: 'SRD' }, // Surinamese Dollar
  },
  bs: {
    lang: 'en',
    country: 'bs',
    iso3: 'bhs',
    fullCountry: 'Bahamas',
    description: {
      en: 'Bahamas',
      es: 'Bahamas',
      pt: 'Bahamas',
      he: 'איי הבהאמה',
    },
    supportedLanguages: ['es', 'en', 'pt'],
    slug: {
      en: 'the-bahamas',
      es: 'bahamas',
      pt: 'baamas',
    },
    numberFormatter: { lang: 'en-BS', currency: 'BSD' }, // Bahamian Dollar
  },
  tc: {
    lang: 'en',
    country: 'tc',
    iso3: 'tca',
    fullCountry: 'Turks and Caicos Islands',
    description: {
      en: 'Turks and Caicos Islands',
      es: 'Islas Turcas y Caicos',
      pt: 'Ilhas Turcas e Caicos',
      he: 'איי טורקס וקאיקוס',
    },
    supportedLanguages: ['es', 'en', 'pt'],
    slug: {
      en: 'turks-and-caicos-islands',
      es: 'islas-turcas-y-caicos',
      pt: 'ilhas-turcas-e-caicos',
    },
    numberFormatter: { lang: 'en-TC', currency: 'USD' }, // US Dollar
  },
  vg: {
    lang: 'en',
    country: 'vg',
    iso3: 'vgb',
    fullCountry: 'British Virgin Islands',
    description: {
      en: 'British Virgin Islands',
      es: 'Islas Vírgenes Británicas',
      pt: 'Ilhas Virgens Britânicas',
      he: 'איי הבתולה הבריטיים',
    },
    supportedLanguages: ['es', 'en', 'pt'],
    slug: {
      en: 'virgin-islands-british',
      es: 'islas-virgenes-del-reino-unido',
      pt: 'ilhas-virgens-britanicas',
    },
    numberFormatter: { lang: 'en-VG', currency: 'USD' }, // US Dollar
  },
  ar: {
    lang: 'es',
    country: 'ar',
    iso3: 'arg',
    fullCountry: 'Argentina',
    description: {
      en: 'Argentina',
      es: 'Argentina',
      pt: 'Argentina',
      he: 'ארגנטינה',
    },
    supportedLanguages: ['es', 'en', 'pt'],
    slug: {
      en: 'argentina',
      es: 'argentina',
      pt: 'argentina',
    },
    numberFormatter: { lang: 'es-AR', currency: 'ARS' }, // Argentine Peso
  },
  mx: {
    lang: 'es',
    country: 'mx',
    iso3: 'mex',
    fullCountry: 'Mexico',
    description: {
      en: 'Mexico',
      es: 'México',
      pt: 'México',
      he: 'מקסיקו',
    },
    supportedLanguages: ['es', 'en', 'pt'],
    slug: {
      en: 'mexico',
      es: 'mexico',
      pt: 'mexico',
    },
    numberFormatter: { lang: 'es-MX', currency: 'MXN' }, // Mexican Peso
  },
  br: {
    lang: 'pt',
    country: 'br',
    iso3: 'bra',
    fullCountry: 'Brazil',
    description: {
      en: 'Brazil',
      es: 'Brasil',
      pt: 'Brasil',
      he: 'ברזיל',
    },
    supportedLanguages: ['es', 'en', 'pt'],
    slug: {
      en: 'brazil',
      es: 'brasil',
      pt: 'brasil',
    },
    numberFormatter: { lang: 'pt-BR', currency: 'BRL' }, // Brazilian Real
  },
  pe: {
    lang: 'es',
    country: 'pe',
    iso3: 'per',
    fullCountry: 'Peru',
    description: {
      en: 'Peru',
      es: 'Perú',
      pt: 'Peru',
      he: 'פרו',
    },
    supportedLanguages: ['es', 'en', 'pt'],
    slug: {
      en: 'peru',
      es: 'peru',
      pt: 'peru',
    },
    numberFormatter: { lang: 'es-PE', currency: 'PEN' }, // Peruvian Sol
  },
};

function getIso2FromIso3(iso3) {
  if (!iso3) {
    return undefined;
  }
  for (const countryCode in countryMeta) {
    if (countryMeta[countryCode].iso3 === iso3.toLowerCase()) {
      return countryCode;
    }
  }
  return undefined; // Return undefined if ISO3 code is not found
}

const countryWithinDomain = (url) => {
  if (includes(url, 'il')) return 'il';
  return '';
};

const localeSubpathsCountry = Object.keys(countryMeta);

const localeSubpathsLanguage = Object.keys(localeSubpaths);

const domainSettings = {
  [process.env.NEXT_PUBLIC_DOMAIN_DEFAULT]: {
    defaultLocale: 'en',
    notAllowed: ['he'],
  },
  [process.env.NEXT_PUBLIC_DOMAIN_MEXICO]: {
    // We will keep it to perform the redirects
    defaultLocale: 'es',
    country: 'mx',
    notAllowed: ['he'],
  },
  [process.env.NEXT_PUBLIC_DOMAIN_BRAZIL]: {
    // We will keep it to perform the redirects
    defaultLocale: 'pt',
    country: 'br',
    notAllowed: ['he'],
  },
  [process.env.NEXT_PUBLIC_DOMAIN_ARGENTINA]: {
    // We will keep it to perform the redirects
    defaultLocale: 'es',
    country: 'ar',
    notAllowed: ['he'],
  },
  [process.env.NEXT_PUBLIC_DOMAIN_PERU]: {
    // We will keep it to perform the redirects
    defaultLocale: 'es',
    country: 'pe',
    notAllowed: ['he'],
  },
  [process.env.NEXT_PUBLIC_DOMAIN_ISRAEL]: {
    defaultLocale: 'he',
    country: 'il',
    notAllowed: ['es', 'pt'],
  },
};

const oldLatinAmericaDomains = [
  process.env.NEXT_PUBLIC_DOMAIN_MEXICO,
  process.env.NEXT_PUBLIC_DOMAIN_BRAZIL,
  process.env.NEXT_PUBLIC_DOMAIN_ARGENTINA,
  process.env.NEXT_PUBLIC_DOMAIN_PERU,
];

module.exports = {
  localeSubpaths,
  localeSubpathsCountry,
  localeSubpathsLanguage,
  domainSettings,
  countryMeta,
  countryWithinDomain,
  languageOptions,
  languageMeta,
  getIso2FromIso3,
  oldLatinAmericaDomains,
};
