/* eslint-disable react/no-unescaped-entities */
/* eslint-disable camelcase */
import { useTranslation } from 'react-i18next';

import duns100Image from 'public/images/duns-100.png';
import dunsCredibilityImageHe from 'public/images/duns-credibility-he.png';
import dunsPremiumImageHe from 'public/images/duns-credibility-premium-he.png';
import dunsPremiumImage from 'public/images/duns-credibility-premium.png';
import dunsCredibilityImage from 'public/images/duns-credibility.png';
import dunsRegisteredImage from 'public/images/duns-registered.png';

export const useWeekDays = () => {
  const { t } = useTranslation('calendar');

  const weekDays = [
    {
      fullDay: t('week_days.monday.fullDay'),
      abbreviation: 'mon',
      order: 1,
      letter: t('week_days.monday.letter'),
    },
    {
      fullDay: t('week_days.tuesday.fullDay'),
      abbreviation: 'tues',
      order: 2,
      letter: t('week_days.tuesday.letter'),
    },
    {
      fullDay: t('week_days.wednesday.fullDay'),
      abbreviation: 'wed',
      order: 3,
      letter: t('week_days.wednesday.letter'),
    },
    {
      fullDay: t('week_days.thursday.fullDay'),
      abbreviation: 'thur',
      order: 4,
      letter: t('week_days.thursday.letter'),
    },
    {
      fullDay: t('week_days.friday.fullDay'),
      abbreviation: 'fri',
      order: 5,
      letter: t('week_days.friday.letter'),
    },
  ];
  return weekDays;
};

export const useWeekendDays = () => {
  const { t } = useTranslation('calendar');
  const weekendDays = [
    {
      fullDay: t('weekend_days.saturday.fullDay'),
      abbreviation: 'sat',
      order: 6,
      letter: t('weekend_days.saturday.letter'),
    },
    {
      fullDay: t('weekend_days.sunday.fullDay'),
      abbreviation: 'sun',
      order: 7,
      letter: t('weekend_days.sunday.letter'),
    },
  ];
  return weekendDays;
};

export const useAllDays = () => {
  const weekDays = useWeekDays();
  const weekendDays = useWeekendDays();
  const days = [...weekDays, ...weekendDays];

  return days;
};

export const GalleryCategories = {
  All: {
    type: 'all',
  },
  Photos: {
    type: 'image',
  },
  Videos: {
    type: 'video',
  },
  Downloads: {
    type: 'file',
  },
};

export const BadgeType = {
  duns_registered: {
    name: {
      en: 'D-U-N-S® Registered™',
      es: 'D-U-N-S® Registered™',
      pt: 'D-U-N-S® Registered™',
      he: 'D-U-N-S® רשום',
    },
    title: {
      en: 'D-U-N-S® Registered™',
      es: 'D-U-N-S® Registered™',
      pt: 'D-U-N-S® Registered™',
      he: 'D-U-N-S® רשום',
    },
    description: {
      en: (
        <>The D-U-N-S® Registered™ seal indicates that a company has been verified and validated by Dun & Bradstreet.</>
      ),
      es: <>El sello D-U-N-S® Registered™ indica que una empresa ha sido verificada y validada por Dun & Bradstreet.</>,
      pt: <>O selo D-U-N-S® Registered™ indica que uma empresa foi verificada e validada pela Dun & Bradstreet.</>,
      he: <>החותם הרשום D-U-N-S® מציין שחברה אומתה ואומתה על ידי Dun & Bradstreet.</>,
    },
    image: {
      en: dunsRegisteredImage,
      es: dunsRegisteredImage,
      pt: dunsRegisteredImage,
      he: dunsRegisteredImage,
    },
  },
  duns_credibility: {
    name: {
      en: 'D-U-N-S® Credibility',
      es: 'D-U-N-S® Credibility',
      pt: 'D-U-N-S® Credibility',
      he: 'דאנס אמינות',
    },
    title: {
      en: 'D-U-N-S® Credibility',
      es: 'D-U-N-S® Credibility',
      pt: 'D-U-N-S® Credibility',
      he: 'דאנס אמינות',
    },
    description: {
      en: (
        <>
          D&B's Credibility Mark is a seal of approval designed to reflect a level of business credibility and ease a
          consumer's decision making process as they move towards a business transaction. This stamp is based on our
          professional opinion and on the information we have as to the quality levels of the products or services
          offered.
        </>
      ),
      es: (
        <>
          La marca de credibilidad de D&B es un sello de aprobación diseñado para reflejar un nivel de credibilidad
          comercial y facilitar una proceso de toma de decisiones del consumidor a medida que avanzan hacia una
          transacción comercial. Este sello se basa en nuestro opinión profesional y sobre la información que tenemos
          sobre los niveles de calidad de los productos o servicios Ofrecido.
        </>
      ),
      he: (
        <>
          חותם האמינות של דן אנד ברדסטריט (להלן:"די.בי") הינו "תו איכות" אשר מטרתו להקל על תהליך קבלת ההחלטות של הצרכן
          לקראת ביצוע התקשרות עסקית עם בית העסק. הענקת החותם מתבססת על הערכתנו המקצועית, על סמך המידע הקיים בידי די.בי,
          באשר לרמת הסיכון הפיננסי שבהתקשרות עסקית עם בית עסק.
        </>
      ),
      pt: (
        <>
          A Marca de Credibilidade da D&B é um selo de aprovação criado para refletir um nível de credibilidade
          comercial e facilitar uma processo de tomada de decisão do consumidor à medida que se move em direção a uma
          transação comercial. Este selo é baseado em nosso opinião profissional e nas informações que temos quanto aos
          níveis de qualidade dos produtos ou serviços oferecido.
        </>
      ),
    },
    image: {
      en: dunsCredibilityImage,
      es: dunsCredibilityImage,
      he: dunsCredibilityImageHe,
      pt: dunsCredibilityImage,
    },
  },
  duns_credibility_premium: {
    name: {
      en: 'D-U-N-S® Credibility Premium',
      es: 'D-U-N-S® Credibility Premium',
      pt: 'D-U-N-S® Credibility Premium',
      he: 'פרמיית אמינות D-U-N-S®',
    },
    title: {
      en: 'D-U-N-S® Credibility Premium',
      es: 'D-U-N-S® Credibility Premium',
      pt: 'D-U-N-S® Credibility Premium',
      he: 'פרמיית אמינות D-U-N-S®',
    },
    description: {
      en: (
        <>
          D&B's Credibility Mark is a seal of approval designed to reflect a level of business credibility and ease a
          consumer's decision making process as they move towards a business transaction. This stamp is based on our
          professional opinion and on the information we have as to the quality levels of the products or services
          offered.
        </>
      ),
      es: (
        <>
          La marca de credibilidad de D&B es un sello de aprobación diseñado para reflejar un nivel de credibilidad
          comercial y facilitar una proceso de toma de decisiones del consumidor a medida que avanzan hacia una
          transacción comercial. Este sello se basa en nuestro opinión profesional y sobre la información que tenemos
          sobre los niveles de calidad de los productos o servicios Ofrecido.
        </>
      ),
      he: (
        <>
          חותם האמינות של דן אנד ברדסטריט (להלן:"די.בי") הינו "תו איכות" אשר מטרתו להקל על תהליך קבלת ההחלטות של הצרכן
          לקראת ביצוע התקשרות עסקית עם בית העסק. הענקת החותם מתבססת על הערכתנו המקצועית, על סמך המידע הקיים בידי די.בי,
          באשר לרמת הסיכון הפיננסי שבהתקשרות עסקית עם בית עסק.
        </>
      ),
      pt: (
        <>
          A Marca de Credibilidade da D&B é um selo de aprovação criado para refletir um nível de credibilidade
          comercial e facilitar uma processo de tomada de decisão do consumidor à medida que se move em direção a uma
          transação comercial. Este selo é baseado em nosso opinião profissional e nas informações que temos quanto aos
          níveis de qualidade dos produtos ou serviços oferecido.
        </>
      ),
    },
    image: {
      en: dunsPremiumImage,
      es: dunsPremiumImage,
      he: dunsPremiumImageHe,
      pt: dunsPremiumImage,
    },
  },
  duns_100: {
    name: {
      en: 'D-U-N-S® 100',
      es: 'D-U-N-S® 100',
      pt: 'D-U-N-S® 100',
      he: 'D-U-N-S® 100',
    },
    title: {
      en: 'D-U-N-S® 100',
      es: 'D-U-N-S® 100',
      pt: 'D-U-N-S® 100',
      he: 'D-U-N-S® 100',
    },
    description: {
      en: (
        <>
          The D-U-N-S®'s 100 rankings present the leading companies in a range of sectors in the economy and reflect
          Israel's business reality in a reliable, accurate and professional way.
          <br />
          The rankings are based on set, defined and measured criteria and are undertaken by Dun & Bradstreet Israel
          Group economists.
        </>
      ),
      es: (
        <>
          Las 100 clasificaciones de D-U-N-S® presentan las empresas líderes en una variedad de sectores de la economía
          y reflejan la posición de Israel realidad empresarial de forma fiable, precisa y profesional.
          <br />
          Las clasificaciones se basan en criterios establecidos, definidos y medidos y están a cargo de Dun &
          Bradstreet Israel. Economistas del grupo.
        </>
      ),
      he: (
        <>
          D-U-N-S®'s 100 - דירוג החברות המובילות במשק הישראלי, מלווה את המשק כבר 40 שנים, מציג את החברות המובילות בענפים
          השונים ומשקף את המציאות העסקית בישראל בצורה מהימנה, מדויקת ומקצועית.
          <br />
          דירוג DUN'S 100 הינו דירוג מקצועי, אובייקטיבי ומהימן, המתבסס על קריטריונים קבועים, מוגדרים ומדידים, בהתאם
          לענפי המשק השונים.
          <br />
          הופעה בדירוג DUN'S 100 מעידה על השתייכות לקבוצת החברות המובילות והמצליחות במשק הישראלי, ומהווה חותם של איכות
          לחברה ולמנהליה.
        </>
      ),
      pt: (
        <>
          O ranking D-U-N-S®'s 100 apresenta as empresas líderes em diversos setores da economia e reflete a posição de
          Israel realidade empresarial de forma confiável, precisa e profissional.
          <br />
          As classificações são baseadas em critérios definidos e medidos e são realizadas pela Dun & Bradstreet Israel
          Economistas do grupo.
        </>
      ),
    },
    image: {
      en: duns100Image,
      es: duns100Image,
      pt: duns100Image,
      he: duns100Image,
    },
  },
};

export const TermsAndConditions = {
  en: 'https://www.iubenda.com/terms-and-conditions/22403288',
  es: 'https://www.iubenda.com/condiciones-de-uso/51270955',
  pt: 'https://www.iubenda.com/termos-e-condicoes/42598177',
  // TO DO -- HE
};

export const PrivacyPolicy = {
  en: 'https://www.iubenda.com/privacy-policy/22403288',
  es: 'https://www.iubenda.com/privacy-policy/51270955',
  pt: 'https://www.iubenda.com/privacy-policy/42598177',
  // TO DO -- HE
};

export const CookieApiHeader = {
  en: `var _iub = _iub || [];_iub.csConfiguration = {"countryDetection":true,"consentOnDocument":true,"lang":"en","siteId":1978584,"floatingPreferencesButtonIcon":true,"floatingPreferencesButtonHover":true,"cookiePolicyId":22403288, "banner":{ "brandBackgroundColor":"none","brandTextColor":"black","logo":"https://www.cialdnb.com/wp-content/uploads/2020/01/logo-cialdnb-color.png","acceptButtonDisplay":true,"customizeButtonDisplay":true,"acceptButtonColor":"#015172","acceptButtonCaptionColor":"white","customizeButtonColor":"#DADADA","customizeButtonCaptionColor":"#4D4D4D","rejectButtonDisplay":true,"rejectButtonColor":"rgba(0.73, 81.27, 114.5, 0.45)","rejectButtonCaptionColor":"white","position":"float-bottom-left","textColor":"black","backgroundColor":"white" }};`,
  es: `var _iub = _iub || [];_iub.csConfiguration = {"countryDetection":true,"consentOnDocument":true,"lang":"es","siteId":1978584,"floatingPreferencesButtonIcon":true,"floatingPreferencesButtonHover":true,"cookiePolicyId":51270955, "banner":{ "brandBackgroundColor":"none","brandTextColor":"black","logo":"https://www.cialdnb.com/wp-content/uploads/2020/01/logo-cialdnb-color.png","acceptButtonDisplay":true,"customizeButtonDisplay":true,"acceptButtonColor":"#015172","acceptButtonCaptionColor":"white","customizeButtonColor":"#DADADA","customizeButtonCaptionColor":"#4D4D4D","rejectButtonDisplay":true,"rejectButtonColor":"rgba(0.73, 81.27, 114.5, 0.45)","rejectButtonCaptionColor":"white","position":"float-bottom-left","textColor":"black","backgroundColor":"white" }};`,
  pt: `var _iub = _iub || [];_iub.csConfiguration = {"countryDetection":true,"consentOnDocument":true,"lang":"pt-BR","siteId":1978584,"floatingPreferencesButtonIcon":true,"floatingPreferencesButtonHover":true,"cookiePolicyId":42598177, "banner":{ "brandBackgroundColor":"none","brandTextColor":"black","logo":"https://www.cialdnb.com/wp-content/uploads/2020/01/logo-cialdnb-color.png","acceptButtonDisplay":true,"customizeButtonDisplay":true,"acceptButtonColor":"#015172","acceptButtonCaptionColor":"white","customizeButtonColor":"#DADADA","customizeButtonCaptionColor":"#4D4D4D","rejectButtonDisplay":true,"rejectButtonColor":"rgba(0.73, 81.27, 114.5, 0.45)","rejectButtonCaptionColor":"white","position":"float-bottom-left","textColor":"black","backgroundColor":"white" }};`,
};

export const TypeOfIndustries = [
  {
    en: 'Construction',
    es: 'Construcción',
    pt: 'Construção',
    he: 'בְּנִיָה',
  },
  {
    en: 'Cosmetics',
    es: 'Cosmética',
    pt: 'Cosméticos',
    he: 'קוסמטיקה',
  },
  {
    en: 'Energy',
    es: 'Energia',
    pt: 'Energia',
    he: 'אֵנֶרְגִיָה',
  },
  {
    en: 'Financial Services',
    es: 'Servicios Financieros',
    pt: 'Serviços financeiros',
    he: 'שירותים פיננסיים',
  },
  {
    en: 'Food & Beverage',
    es: 'Alimentos y Bebidas',
    pt: 'Comida & Bebida',
    he: 'אוכל ומשקה',
  },
  {
    en: 'Manufacturing',
    es: 'Fabricación',
    pt: 'Manufatura',
    he: 'ייצור',
  },
  {
    en: 'Pharmaceutical',
    es: 'Farmaceutica',
    pt: 'Farmacêutica',
    he: 'תרופות',
  },
  {
    en: 'Retail',
    es: 'Venta minorista',
    pt: 'Varejo',
    he: 'קמעונאות',
  },
  {
    en: 'Services',
    es: 'Servicios',
    pt: 'Serviços',
    he: 'שירותים',
  },
  {
    en: 'Technology',
    es: 'Tecnología',
    pt: 'Tecnologia',
    he: 'טֶכנוֹלוֹגִיָה',
  },
  {
    en: 'Telecomm',
    es: 'Telecomunicaciones',
    pt: 'Telecomunicações',
    he: 'טלקום',
  },
  {
    en: 'Transportation & Auto',
    es: 'Transporte y Automóviles',
    pt: 'Transporte e Automóvel',
    he: 'תחבורה ואוטו',
  },
  {
    en: 'Wholesale',
    es: 'Venta mayorista',
    pt: 'Atacado',
    he: 'בסיטונאות',
  },
  {
    en: 'Other',
    es: 'Otro',
    pt: 'Outros',
    he: 'אַחֵר',
  },
];

export const TypeOfIndustriesLang = (lang) => {
  switch (lang) {
    case 'es':
      return TypeOfIndustries.map((item) => item.es);
    case 'en':
      return TypeOfIndustries.map((item) => item.en);
    case 'pt':
      return TypeOfIndustries.map((item) => item.pt);
    default:
      return TypeOfIndustries.map((item) => item.en);
  }
};

export const NumberOfEmployees = [
  { label: '0 - 50', value: '0_to_49' },
  { label: '50 - 100', value: '50_to_99' },
  { label: '100 - 250', value: '100_to_249' },
  { label: '250 - 500', value: '250_to_499' },
  { label: '500 +', value: 'over_500' },
];

export const DunsApplicationReason = (country) => {
  const { t } = useTranslation('forms');

  const DunsApplicationReasonAllCountries = (t) => [
    {
      value: 'default',
      label: t('duns_application.step1.supplier_customer_partner'),
    },
    {
      value: 'apple',
      label: t('duns_application.step1.apple'),
    },
    {
      value: 'fda',
      label: t('duns_application.step1.fda'),
    },
    {
      value: 'google',
      label: t('duns_application.step1.google'),
    },
    {
      value: 'us_government',
      label: t('duns_application.step1.us_government'),
    },
  ];

  const DunsApplicationReasonByCountry = {
    other: [...DunsApplicationReasonAllCountries(t)],
    mex: [
      ...DunsApplicationReasonAllCountries(t),
      {
        value: 'cial_360_customer',
        label: t('duns_application.step1.cial_360_customer'),
      },
    ],
    arg: [
      ...DunsApplicationReasonAllCountries(t),
      {
        value: 'volkswagen',
        label: t('duns_application.step1.volkswagen'),
      },
    ],
  };

  return DunsApplicationReasonByCountry[country];
};
