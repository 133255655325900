import { get, isEmpty } from 'lodash';

// Note: You probably want to be importing these functions from InternationalizationContext
// Beacause it will provide the correct locale automatically
// Importing directly from this file should be used when react's context API is not available

// Object can either be an object that contains the key you pass,
// or a translation object {en: 'dogs', es: 'perro'}. In the latter case, you don't need to pass the key.

export const getTranslated = (object, key, language, defaultValue, options = {}) => {
  const { useDefault = true } = options;

  // You can pass the translation object directly as the 'object' if you would like
  const attribute = key ? get(object, key) : object;

  if (isEmpty(attribute)) return defaultValue || '';

  if (typeof attribute === 'object') {
    const attributeTranslated = get(attribute, language);

    // Has translation for selected language
    if (attributeTranslated) return attributeTranslated;

    // No translation for selected language
    // If there isn't a translation for the selected language return some default translation
    // First option is select the user language

    if (useDefault) {
      const attributeDefaultTranslated = Object.values(attribute).find((item) => !isEmpty(item));

      if (attributeDefaultTranslated) return attributeDefaultTranslated;
    }

    return defaultValue || '';
  }

  // Translation object or normal attribute without translation
  return attribute || get(object, language) || defaultValue;
};

export const getSlug = (object, language) => getTranslated(object, 'slug', language);

export const getSearchPath = (query) => {
  let path = '/companies';
  if (query.location) {
    path += `/locations/${query.location}`;
  }
  if (query.industry) {
    path += `/industries/${query.industry}`;
  }
  return path;
};
