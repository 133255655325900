/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
/* eslint-disable prettier/prettier */

export enum ProductEnum {
  ACCESS = 'D-U-N-S® ACCESS',
  BASIC = 'D-U-N-S® BASIC',
  SILVER = 'D-U-N-S® SILVER',
  GOLD = 'D-U-N-S® GOLD',
}

export enum ProductsEnum {
  Dunsguide = 'Dunsguide',
  DUNSNumber = 'DUNSNumber',
  DUNSRegistered = 'DUNSRegistered',
  CredibilityCertificate = 'CredibilityCertificate',
  NameReport = 'NameReport',
  ESGRegistered = 'ESGRegistered',
  SQRReport = 'SQRReport',
  BIRReport = 'BIRReport',
  ProspectaCredits = 'ProspectaCredits',
  C360Credits = 'C360Credits',
  Pulso = 'Pulso',
}

export enum ProductStatusEnum {
  Active = 'Active',
  Cancelled = 'Cancelled',
  Upgrade = 'Upgrade',
  Processing = 'Processing',
  Delivered = 'Delivered',
  WaitingOnInformation = 'WaitingOnInformation',
  Error = 'Error',
}

export enum ProductStatusColors {
  Active = 'green',
  Cancelled = 'gray',
  Upgrade = 'orange',
  Processing = 'blue',
  Delivered = 'green',
  WaitingOnInformation = 'yellow',
  Error = 'red',
}
