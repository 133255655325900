import React, { createContext } from 'react';

import PropTypes from 'prop-types';

export const IsLoggedInContext = createContext();

const propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

const IsLoggedInContextProvider = ({ isLoggedIn, children }) => {
  return <IsLoggedInContext.Provider value={{ isLoggedIn }}>{children}</IsLoggedInContext.Provider>;
};

IsLoggedInContextProvider.propTypes = propTypes;

export default IsLoggedInContextProvider;
