const splitEmails = (emails) =>
  emails?.length ? emails.reduce((acc, email) => [...acc, ...email.email?.replace(/\s/g, '').split(',')], []) : [];

const removeRepeatedElements = (array) => {
  const dataArr = new Set(array);
  return [...dataArr];
};

export const getEmails = (company) => {
  const formatEmails = removeRepeatedElements(splitEmails(company?.emails));
  const emails = formatEmails.length
    ? formatEmails.sort((first, second) => first.order - second.order)
    : company?.autobahnEmails?.map((item) => item.address);

  if (!emails || !emails?.length) return null;
  return emails;
};

export const getPhones = (company) => {
  const phones = company?.phones?.length
    ? company.phones
        ?.filter((item) => item.type === 'phone')
        ?.sort((first, second) => first.order - second.order)
        ?.map((item) => item.number)
    : company?.autobahnPhones?.filter((item) => item.type === 'landline').map((item) => item.number);

  if (!phones || !phones?.length) return null;
  return phones;
};

export const getFaxes = (company) => {
  const faxes = company?.phones?.length
    ? company.phones
        ?.filter((item) => item.type === 'fax')
        ?.sort((first, second) => first.order - second.order)
        ?.map((item) => item.number)
    : company?.autobahnPhones?.filter((item) => item.type === 'fax').map((item) => item.number);

  if (!faxes || !faxes?.length) return null;
  return faxes;
};
