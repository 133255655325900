import { AdminCompanyDto, ClaimRequestStatus } from './models/Company';
import { User } from './models/User';
import { Workflow } from './models/Workflow';

/*

// Getters used to extract specific parts of the user state, such as companies and claims.

example of usage

const user = useUser()
const company = getCompanyById(123, user);

*/

export const getCompanies = (user?: User): AdminCompanyDto[] => {
  return user ? user.companies : [];
};

export const getCompanyById = (id?: string, user?: User): AdminCompanyDto | undefined => {
  return getCompanies(user).find((company) => company.aclCompanyId === id);
};

export const getUniqueWorkflows = (user?: User): Workflow[] => {
  if (!user) return [];

  const workflowsSet = new Map<string, Workflow>();

  // Add workflows directly from the user
  user.workflows.forEach((workflow) => {
    if (!workflowsSet.has(workflow.id)) {
      workflowsSet.set(workflow.id, workflow);
    }
  });

  // Add workflows from the user's companies
  user.companies.forEach((company) => {
    company.workflows.forEach((workflow) => {
      if (!workflowsSet.has(workflow.id)) {
        workflowsSet.set(workflow.id, workflow);
      }
    });
  });

  return Array.from(workflowsSet.values());
};

export const getWorkflowById = (id?: string, user?: User): Workflow | undefined => {
  return getUniqueWorkflows(user).find((workflow) => workflow.id === id);
};

export const getCompanyByDuns = (
  duns?: string,
  user?: User,
  requiresApproval: boolean = false,
): AdminCompanyDto | undefined => {
  return getCompanies(user).find(
    (company) => (!requiresApproval || company.claimStatus === ClaimRequestStatus.APPROVED) && company.duns === duns,
  );
};

/*

// Selectors used in conjunction with the "useUser" hook to retrieve specific information from the user object.

example of usage

const company = useUser(selectCompanyById(123))

*/

export const selectCompanyById = (id: string) => (user?: User) => {
  return getCompanyById(id, user);
};

export const selectWorkflowById = (id: string) => (user?: User) => {
  return getWorkflowById(id, user);
};

export const selectUserAndCompanyById = (id: string) => (user?: User) => {
  const company = getCompanyById(id, user);
  return {
    user,
    company,
  };
};

export const selectUserAndCompanyByDuns = (duns?: string) => (user?: User) => {
  const company = getCompanyByDuns(duns, user);
  return {
    user,
    company,
  };
};

export const selectUserAndCompanyByDunsOrId = (dunsOrId?: string) => (user?: User) => {
  const company = getCompanyByDuns(dunsOrId, user) || getCompanyById(dunsOrId, user);
  return {
    user,
    company,
  };
};
