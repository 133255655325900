import React, { useState, useEffect, createContext, useMemo /*, useContext */ } from 'react';

import PropTypes from 'prop-types';

// TODO: Commenting this piece of code once it's doing nothing and analytics is not enabled yet
// import { isUuid } from 'utils/uuid';
// import { useGetProfileViewsQuery } from 'store/api';
// import { RouterContext } from 'components/_context/RouterContext';

export const FlagsContext = createContext();

const propTypes = {
  children: PropTypes.node.isRequired,
};

const ENABLE_ANALYTICS = process.env.NEXT_PUBLIC_ENABLE_ANALYTICS === 'true';
// TODO: Commenting this piece of code once it's doing nothing and analytics is not enabled yet
// const ENABLE_ANALYTICS_PROFILE_VIEWS = 100;

// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
const FlagsContextProvider = ({ children }) => {
  // TODO: Commenting this piece of code once it's doing nothing and analytics is not enabled yet
  // const { router } = useContext(RouterContext);

  // const { companyId } = router.query;

  // const { data: profileViews } = useGetProfileViewsQuery(companyId, {
  //   skip: !companyId || isUuid(companyId),
  // });

  const [shouldShowAnalytics, setShouldShowAnalytics] = useState(ENABLE_ANALYTICS);

  const contextValue = useMemo(
    () => ({
      shouldShowAnalytics,
    }),
    [shouldShowAnalytics],
  );

  useEffect(() => {
    // TODO: Commenting this piece of code once it's doing nothing and analytics is not enabled yet
    // if (ENABLE_ANALYTICS) {
    //   if (countryParam === 'il' && profileViews > ENABLE_ANALYTICS_PROFILE_VIEWS) {
    //     // If country is israel
    //   }
    // }

    setShouldShowAnalytics(ENABLE_ANALYTICS);
  }, []);

  return <FlagsContext.Provider value={contextValue}>{children}</FlagsContext.Provider>;
};

FlagsContextProvider.propTypes = propTypes;

export default FlagsContextProvider;
