import qs from 'qs';

import { localeSubpathsLanguage } from './localeConstants';

export const getPathWithoutLanguage = (path) => {
  const pathSegments = path?.split('/');
  const pathSegmentsWithoutLanguage = pathSegments?.filter((segment) => !localeSubpathsLanguage?.includes(segment));
  return pathSegmentsWithoutLanguage?.join('/');
};

export const removeParamsFromPath = (path, includeParams = []) => {
  const pathWithoutParams = path.split('?')[0] || path;

  const paramsObject = qs.parse(path.split('?')[1]);

  const filteredParamsObject = Object.fromEntries(
    Object.entries(paramsObject).filter(([k]) => includeParams?.includes(k)),
  );

  const sortedFilteredParams = {};

  Object.keys(filteredParamsObject)
    ?.sort()
    .forEach((key) => {
      sortedFilteredParams[key] = filteredParamsObject[key];
    });

  const filteredParams = qs.stringify(sortedFilteredParams);
  return `${pathWithoutParams}${filteredParams.length ? `?${filteredParams}` : ''}`;
};

export const getLanguageAlternates = (path, options) => {
  const formattedPath = removeParamsFromPath(path, options?.includeParams);

  let pathWithoutLanguage = getPathWithoutLanguage(formattedPath);

  pathWithoutLanguage = pathWithoutLanguage === '/' ? '' : pathWithoutLanguage;

  return [
    {
      hrefLang: 'es',
      href: `${process.env.NEXT_PUBLIC_APP_PROTOCOL || 'https'}://${
        process.env.NEXT_PUBLIC_DOMAIN_DEFAULT
      }/es${pathWithoutLanguage}`,
    },
    {
      hrefLang: 'es-419', // Latin America and Caribbean
      href: `${process.env.NEXT_PUBLIC_APP_PROTOCOL || 'https'}://${
        process.env.NEXT_PUBLIC_DOMAIN_DEFAULT
      }/es${pathWithoutLanguage}`,
    },
    {
      hrefLang: 'es-MX', // Mexico
      href: `${process.env.NEXT_PUBLIC_APP_PROTOCOL || 'https'}://${
        process.env.NEXT_PUBLIC_DOMAIN_DEFAULT
      }/es${pathWithoutLanguage}`,
    },
    {
      hrefLang: 'es-AR', // Argentina
      href: `${process.env.NEXT_PUBLIC_APP_PROTOCOL || 'https'}://${
        process.env.NEXT_PUBLIC_DOMAIN_DEFAULT
      }/es${pathWithoutLanguage}`,
    },
    {
      hrefLang: 'es-PE', // Peru
      href: `${process.env.NEXT_PUBLIC_APP_PROTOCOL || 'https'}://${
        process.env.NEXT_PUBLIC_DOMAIN_DEFAULT
      }/es${pathWithoutLanguage}`,
    },
    {
      hrefLang: 'es-GT', // Guatemala
      href: `${process.env.NEXT_PUBLIC_APP_PROTOCOL || 'https'}://${
        process.env.NEXT_PUBLIC_DOMAIN_DEFAULT
      }/es${pathWithoutLanguage}`,
    },
    {
      hrefLang: 'es-EC', // Ecuador
      href: `${process.env.NEXT_PUBLIC_APP_PROTOCOL || 'https'}://${
        process.env.NEXT_PUBLIC_DOMAIN_DEFAULT
      }/es${pathWithoutLanguage}`,
    },
    {
      hrefLang: 'es-CL', // Chile
      href: `${process.env.NEXT_PUBLIC_APP_PROTOCOL || 'https'}://${
        process.env.NEXT_PUBLIC_DOMAIN_DEFAULT
      }/es${pathWithoutLanguage}`,
    },
    {
      hrefLang: 'pt-BR', // Brazil
      href: `${process.env.NEXT_PUBLIC_APP_PROTOCOL || 'https'}://${
        process.env.NEXT_PUBLIC_DOMAIN_DEFAULT
      }/pt${pathWithoutLanguage}`,
    },
    {
      hrefLang: 'en',
      href: `${process.env.NEXT_PUBLIC_APP_PROTOCOL || 'https'}://${
        process.env.NEXT_PUBLIC_DOMAIN_DEFAULT
      }${pathWithoutLanguage}`,
    },
    {
      hrefLang: 'x-default',
      href: `${process.env.NEXT_PUBLIC_APP_PROTOCOL || 'https'}://${
        process.env.NEXT_PUBLIC_DOMAIN_DEFAULT
      }${pathWithoutLanguage}`,
    },
  ];
};
