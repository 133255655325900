import { useRef, useEffect, useState } from 'react';

// https://usehooks-ts.com/react-hook/use-is-first-render
export function useIsFirstRender() {
  const isFirst = useRef(true);

  if (isFirst.current) {
    isFirst.current = false;

    return true;
  }

  return isFirst.current;
}

export const useElementSize = () => {
  const [elementSize, setElementSize] = useState({ width: 0, height: 0 });
  const elementRef = useRef(null);

  useEffect(() => {
    const updateElementSize = () => {
      if (elementRef.current) {
        const { width, height } = elementRef.current.getBoundingClientRect();
        setElementSize({ width, height });
      }
    };

    if (typeof window !== 'undefined') {
      updateElementSize();

      const resizeObserver = new ResizeObserver(updateElementSize);
      if (elementRef.current) {
        resizeObserver.observe(elementRef.current);
      }

      return () => {
        resizeObserver.disconnect();
      };
    }

    // Return a default value during SSR
    return () => {
      // Do nothing
    };
  }, []);

  return { elementRef, ...elementSize };
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    if (typeof window !== 'undefined') {
      handleResize();

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }

    // Return a default value during SSR
    return () => {
      // Do nothing
    };
  }, []);

  return windowSize;
};

export const usePolling = ({ queryFunction, onRetriesUpdated, onPollingInitialize } = {}, options = {}) => {
  const { interval = 5000, subscribe = true, maxRetries = 1000 } = options;

  const [retries, setRetries] = useState(0);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!subscribe) {
      if (initialized) {
        setInitialized(false);
        if (onPollingInitialize) {
          onPollingInitialize();
        }
      }
      return; // Don't start polling if subscribe is set to false
    }

    if (!initialized) {
      setInitialized(true);
    }

    const fetchDataInterval = setInterval(async () => {
      if (queryFunction) {
        queryFunction();
      }

      setRetries((prevRetries) => prevRetries + 1);
      if (onRetriesUpdated) {
        onRetriesUpdated();
      }
      if (retries + 1 >= maxRetries) {
        clearInterval(fetchDataInterval);
      }
    }, interval);

    return () => {
      clearInterval(fetchDataInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryFunction, interval, subscribe, maxRetries]);

  return {
    isPollingRunning: initialized && subscribe && retries < maxRetries,
    isPollingInitialized: initialized,
  };
};
