import { capitalize } from 'lodash';

import { domainSettings } from 'utils/localeConstants';

function getLocaleForOldDomains({ host, language }: { host: string; language: string | null }): {
  language: string;
  defaultLanguage: string;
} {
  const settings = domainSettings[host];

  return {
    language: language || settings?.defaultLocale,
    defaultLanguage: settings?.defaultLocale,
  };
}

function getLocale({ language }: { language: string | null }): {
  language: string;
  defaultLanguage: string;
} {
  const defaultLanguage = 'en';
  return {
    language: language || defaultLanguage,
    defaultLanguage,
  };
}

function getLanguageFromPathName(pathname: string): string | null {
  const language = pathname.split('/')?.[1];
  // Test if language is one of the specified values
  if (language && ['en', 'es', 'pt', 'he'].includes(language)) {
    return language;
  }
  return null;
}

function getMonthName(
  monthNumber: number,
  {
    locale,
    dateStyle = 'short',
  }: {
    locale: string;
    dateStyle?: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow' | undefined;
  },
): string {
  const date = new Date();
  date.setMonth(monthNumber - 1);

  return capitalize(
    date.toLocaleString(locale, {
      month: dateStyle,
    }),
  );
}

export { getLocale, getLocaleForOldDomains, getLanguageFromPathName, getMonthName };
